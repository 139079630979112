import { Box } from "@mui/material";
import React from "react";


export default function IssueForm() {

    const formUrl = "https://forms.office.com/Pages/ResponsePage.aspx?id=NgOajbiEUkKSF4VOZ0T0fHOD8o4KrQZLucEwIygarStUN1A3TlBKRDdXUTRLUURaMUJFQlVFVkk2TC4u&embed=true"

    return (
        <React.Fragment>
            <Box
                sx={{
                    height: "100vh",
                    background: "linear-gradient(135deg, rgba(22,89,87,1) 0%, rgba(38,83,128,1) 100%)"
                }}
            >
                <iframe
                    title="Issue Report Form"
                    width="100%"
                    height="100%"
                    src={formUrl}
                    style={{ border: 'none', maxWidth: '100%', maxHeight: '100vh', display: "block" }}
                    allowFullScreen
                />
            </Box>
        </React.Fragment>
    )
}