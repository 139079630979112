import React, { ReactNode, createContext, useContext } from "react";

interface UserContextInterface {
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
    formOfAddress: string
    setFormOfAddress: React.Dispatch<React.SetStateAction<string>>
    title: string
    setTitle: React.Dispatch<React.SetStateAction<string>>
    firstName: string
    setFirstName: React.Dispatch<React.SetStateAction<string>>
    lastName: string
    setLastName: React.Dispatch<React.SetStateAction<string>>
}

export const UserContext = createContext<UserContextInterface>(
    {
        email: "",
        setEmail: () => { },
        formOfAddress: "",
        setFormOfAddress: () => { },
        title: "",
        setTitle: () => { },
        firstName: "",
        setFirstName: () => { },
        lastName: "",
        setLastName: () => { }
    }
)

export const useUser = () => useContext(UserContext)

type Props = {
    children: ReactNode
}

export const UserContextProvider: React.FC<Props> = (props: Props) => {
    const [email, setEmail] = React.useState<string>("")
    const [formOfAddress, setFormOfAddress] = React.useState<string>("Keine Angabe")
    const [title, setTitle] = React.useState<string>("")
    const [firstName, setFirstName] = React.useState<string>("")
    const [lastName, setLastName] = React.useState<string>("")

    return (
        <UserContext.Provider
            value={{
                email,
                setEmail,
                formOfAddress,
                setFormOfAddress,
                title,
                setTitle,
                firstName,
                setFirstName,
                lastName,
                setLastName
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}


