import { styled } from "@mui/material"


const Scale = styled('div')(({ theme }) => ({
    '&.fade-enter': {
        opacity: 1,
        transform: "scale(1.02)"
    },
    '&.fade-enter-active': {
        opacity: 1,
        transform: "scale(1.0)",
        transition: "opacity 300ms, transform 300ms linear",

    },
    '&.fade-exit': {
        opacity: 1,
        transform: "scale(1.0)"
    },
    '&.fade-exit-active': {
        opacity: 1,
        transform: "scale(1.02)",
        transition: "opacity 300ms, transform 300ms linear",
    },
}))

export default Scale;