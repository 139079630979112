import React from "react"
import { Button, Typography, Menu, Box, MenuItem } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from '@mui/icons-material/Check';
import { Response } from "../../types"
import { QuestionnaireNode } from "../../types";


type NavigationProps = {
    nodes: QuestionnaireNode[]
    response: Response
    page: number
    onClickOnMenuItem: (position: number) => void
    isTestCompleted: () => boolean
    onCancelQuestionnaire?: () => void
    onFinishQuestionnaire?: () => void
}

export default function Navigation(props: NavigationProps) {

    const { nodes, response, page, onClickOnMenuItem, isTestCompleted, onCancelQuestionnaire, onFinishQuestionnaire } = props

    const [navigationAnchorEl, setNavigationAnchorEl] = React.useState<null | HTMLElement>(null)
    const navigationOpen = Boolean(navigationAnchorEl)

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setNavigationAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setNavigationAnchorEl(null)
    }

    return (
        <React.Fragment>
            {/* Button to open navigation menu */}
            <Button variant="text" aria-label="Open navigation menu" onClick={handleOpen} sx={{ height: "100%", color: "white", borderRadius: 0 }}>
                {"Frage " + page.toString() + " / " + nodes.length.toString()}
                <ArrowDropDownIcon />
            </Button>

            {/* Navigation menu (dropdown) */}
            <Menu aria-label="Questionnaire menu"
                open={navigationOpen}
                onClose={handleClose}
                anchorEl={navigationAnchorEl}
                sx={{ "& .MuiMenu-paper": { backgroundColor: "#0A3131" } }}
            >
                <Box role="navigation" aria-label="Select item" sx={{ overflow: "auto" }}>
                    {nodes.map(node => {
                        return (
                            <MenuItem key={node.content.shortId}
                                value={node.position}
                                onClick={() => {
                                    onClickOnMenuItem(node.position);
                                    handleClose();
                                }}
                                sx={{ display: "flex", maxWidth: 400, gap: 1, color: "white" }}
                            >
                                <Typography noWrap sx={{ flexGrow: 1, typography: {xs: "body2", lg: "body1"} }}>
                                    {node.content.question}
                                </Typography>
                                {
                                    response.items.find(item => item.shortId === node.content.shortId)?.value === "NO_VALUE_SELECTED"
                                        ? (<CheckIcon sx={{ color: "white" }} />)
                                        : (<CheckIcon sx={{ color: "primary.main" }} />)
                                }
                            </MenuItem>
                        )
                    })}
                </Box>
                <Box role="group" sx={{ display: "flex", mt: 1 }}>
                    <Button aria-label="Cancel questionnaire" sx={{ flexGrow: 1 }} onClick={onCancelQuestionnaire}>
                        Abbrechen
                    </Button>
                    <Button aria-label="Finish questionnaire" sx={{ flexGrow: 1 }} disabled={!isTestCompleted()} onClick={onFinishQuestionnaire}>
                        Abschließen
                    </Button>
                </Box>
            </Menu>
        </React.Fragment>
    )
}