import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid, IconButton, Link as MUILink, Stack, Typography } from "@mui/material"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { Check, CompanyInfo } from "../../types";

import TrackingConsent from "../TrackingConsent";


type TrackingConsentProps = {
    open: boolean
    onAgree: () => void
    onDisagree: () => void
}

export type WelcomeScreenProps = {
    check: Check
    companyInfo: CompanyInfo
    onStartCheck: () => void
    trackingConsentProps: TrackingConsentProps
}

export function WelcomeScreen(props: WelcomeScreenProps) {

    const { check, companyInfo, onStartCheck, trackingConsentProps } = props

    return (
        <React.Fragment>
            <Box sx={{ height: "100dvh", overflow: "auto", display: "flex", flexDirection: "column", background: (theme) => ("linear-gradient(135deg, " + theme.palette.secondary.main + " 0%, " + theme.palette.secondary.light + " 100%)"), color: "white" }}>
                {/* Header for the check with logo and title */}
                <Box sx={{ bgcolor: "rgba(0, 0, 0, 0.25)", minHeight: { xs: "100vh", sm: "80vh", md: "40%" }, display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", gap: 5, flexWrap: "wrap" }}>
                    <MUILink href={companyInfo.refLink}>
                        <Box component="img"
                            src={companyInfo.logo}
                            alt={"Logo von " + companyInfo.name}
                            sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: 120, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                        />
                    </MUILink>
                    <Typography sx={{ typography: { xs: "h5", md: "h4" } }} color={"white"}>
                        {check.title}
                    </Typography>
                </Box>
                {/* Check presentation with quick summary */}
                <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: { xs: 10, md: 2 } }}>
                    <Grid container rowSpacing={{ xs: 10, md: 5 }}>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={{ xs: 2, md: 0 }}>
                                <Grid item xs={12} md={8} sx={{ display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
                                    <Typography sx={{ typography: { xs: "h5", md: "h5" } }} textAlign={{ xs: "center", md: "justify" }}>
                                        Der Nachhaltigkeits-Check auf einen Blick
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
                                    <Typography>
                                        Geschätzte Zeit: 15 Minuten
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Stack spacing={{ xs: 5, sm: 5 }}>
                                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 5, justifyContent: { xs: "center", md: "flex-start" }, alignItems: "center" }}>
                                    <Box sx={{ width: 64, height: 64, bgcolor: "rgba(0, 0, 0, 0.25)", borderRadius: "100%", aspectRatio: "1 / 1", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <CheckBoxIcon fontSize="large" sx={{ color: "white" }}/>
                                    </Box>
                                    <Typography maxWidth={{ xs: 500, md: 700 }} textAlign={{ xs: "center", md: "left" }}>
                                        Beantworten Sie 14 Fragen zu den Bereichen Strategie und Führung, Stakeholder-Engagement, Maßnahmen und Transparenz
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 5, justifyContent: { xs: "center", md: "flex-start" }, alignItems: "center" }}>
                                    <Box sx={{ width: 64, height: 64, bgcolor: "rgba(0, 0, 0, 0.25)", borderRadius: "100%", aspectRatio: "1 / 1", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <AssessmentIcon fontSize="large" sx={{ color: "white" }}/>
                                    </Box>
                                    <Typography maxWidth={{ xs: 500, md: 700 }} textAlign={{ xs: "center", md: "left" }}>
                                        Erhalten Sie eine automatisierte Auswertung Ihrer Nachhaltigkeits-Aktivitäten in Vergleich zu anderen Unternehmen
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 5, justifyContent: { xs: "center", md: "flex-start" }, alignItems: "center" }}>
                                    <Box sx={{ width: 64, height: 64, bgcolor: "rgba(0, 0, 0, 0.25)", borderRadius: "100%", aspectRatio: "1 / 1", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <QuestionAnswerIcon fontSize="large" sx={{ color: "white" }}/>
                                    </Box>
                                    <Typography maxWidth={{ xs: 500, md: 700 }} textAlign={{ xs: "center", md: "left" }}>
                                        Treten Sie mit hival in Kontakt zur gemeinsamen Entwicklung eines maßgeschneidertes Vorgehen
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4} sx={{ display: { xs: "none", md: "flex" }, justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <IconButton onClick={onStartCheck} sx={{ bgcolor: "primary.main", boxShadow: 5, width: 128, height: 128, ":hover": { bgcolor: "primary.dark" } }}>
                                    <PlayArrowIcon sx={{ color: "white", height: "75%", width: "75%" }} />
                                </IconButton>
                                <Typography variant="h6" textAlign={"center"} marginTop={1}>
                                    Start
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                {/* Footer */}
                <Box sx={{ height: "48px", bgcolor: "rgba(0, 0, 0, 0.25)", display: "flex", justifyContent: "space-between", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: 2 }}>
                    <MUILink component={Link} to="/privacy/impressum" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Impressum
                    </MUILink>
                    <MUILink component={Link} to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Datenschutz
                    </MUILink>
                    <MUILink component={Link} to="/feedback" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Feedback
                    </MUILink>
                </Box>
                {/* Sticky button for mobile view */}
                <Box sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px", width: "100%" }}>
                    <Button onClick={onStartCheck} fullWidth variant="contained" color="primary" sx={{ borderRadius: 0 }}>
                        Start
                    </Button>
                </Box>
            </Box>
            <TrackingConsent
                open={trackingConsentProps.open}
                onAgree={trackingConsentProps.onAgree}
                onDisagree={trackingConsentProps.onDisagree}
            />
        </React.Fragment>
    )
}
