import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";


type ResponseType = AxiosRequestConfig['responseType'];

type QueryParams = AxiosRequestConfig['params']

type Options = {
    params?: QueryParams
    data?: any,
    responseType?: ResponseType
}

interface APIError extends Error {
    status?: number
    data?: any
}


async function fetchAPI<T>(url: string, options: AxiosRequestConfig = {}): Promise<T> {

    try {
        const response: AxiosResponse<T> = await axios(url, options)
        return response.data as T
    }
    catch (error: unknown) {

        if (axios.isAxiosError(error)) {
            const { response } = error
            if (response) {
                const { status, data } = response
                throw {
                    status,
                    data,
                    message: "API"
                } as APIError
            }
        }
        // Something else happened while making the request or handling the response
        throw {
            message: "An An error has occurred while making the API request"
        } as APIError
    }
}


export interface EndpointDefinition<T = any> {
    url: string | ((...args: any[]) => string)
    method: AxiosRequestConfig['method']
    // Add more properties as needed, such as request headers, query parameters, etc.
}


export async function createAPIRequest<T>(
    endpoint: EndpointDefinition<T>,
    {
        params,
        data,
        responseType
    }: Options
): Promise<T> {
    const url = typeof endpoint.url === 'function' ? endpoint.url(data) : endpoint.url;
    const options: AxiosRequestConfig = {
        method: endpoint.method,
        params,
        data,
        responseType
        // Add more options as needed, such as headers, query parameters, etc.
    };

    return await fetchAPI<T>(url, options);
}