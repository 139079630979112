import React from "react"
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material"
import { QuestionnaireItemOption } from "../../types";
import InfoButton from "./InfoButton";


type Props = {
    position: number
    shortId: string
    question: string
    details?: string
    comment?: string
    options: QuestionnaireItemOption[]
    value?: string
    update: (shortId: string, value: string) => void
}

export default function MultipleChoiceItem(props: Props) {

    const { position, shortId, question, details, comment, options, value, update } = props

    const [commentOpen, setCommentOpen] = React.useState<boolean>(false)

    const handleChange = (value: string) => {
        update(shortId, value)
    }

    const handleCommentClose = () => {
        setCommentOpen(false)
    }

    const handleCommentOpen = () => {
        setCommentOpen(true)
    }

    return (
        <Box>
            <Stack spacing={1}>
                <Typography role="heading" sx={{ typography: { xs: "subtitle2", lg: "h5" }, my: { xs: 2, md: 3, lg: 5 } }}>
                    {(position + 1).toString() + ". " + question}
                </Typography>
                {options.map((option, index) => {
                    if (value === option.value) {
                        return (
                            <Box key={index} onClick={() => handleChange(option.value)} sx={{ backgroundColor: "primary.main", color: "black", p: 3, my: 1, borderRadius: 2, boxShadow: 5, ":hover": { cursor: "pointer" } }}>
                                <Typography sx={{ typography: { xs: "body2", lg: "body1" } }}>{option.label}</Typography>
                            </Box>
                        )
                    } else {
                        return (
                            <Box key={index} onClick={() => handleChange(option.value)} sx={{ background: "rgba(0, 0, 0, 0.25)", p: 3, my: 1, borderRadius: 2, ":hover": { background: "rgba(0, 0, 0, 0.15)", cursor: "pointer" } }}>
                                <Typography sx={{ typography: { xs: "body2", lg: "body1" } }}>{option.label}</Typography>
                            </Box>
                        )
                    }
                })}
                {comment !== undefined && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <InfoButton
                            comment={comment}
                            open={commentOpen}
                            handleOpen={handleCommentOpen}
                            handleClose={handleCommentClose}
                        />
                    </Box>
                )}
            </Stack>
        </Box>
    )
}