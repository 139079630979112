import { EndpointDefinition } from "@/api"


type CreateSessionResponse = {}
type CreateContactResponse = {}
type GetReportResponse = ArrayBuffer
type PostEventResponse = {}

/**
 * Endpoints of the Azure Functions API backend.
 * 
 * Each endpoint definition contains the URL under which the endpoint can be reached and its HTTP method.
 */
export const endpoints = {
    createSession: {
        url: "/api/session",
        method: "POST",
    } as EndpointDefinition<CreateSessionResponse>,
    createContact: {
        url: "/api/contact",
        method: "POST"
    } as EndpointDefinition<CreateContactResponse>,
    getReport: {
        url: "/api/report",
        method: "GET"
    } as EndpointDefinition<GetReportResponse>,
    postEvent: {
        url: "/api/analytics",
        method: "POST"
    }
}