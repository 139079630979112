import React from "react";


interface SessionContextInterface {
    session: string
    setSession: React.Dispatch<React.SetStateAction<string>>
    hasStartedSession: boolean,
    setHasStartedSession: React.Dispatch<React.SetStateAction<boolean>>,
    hasSeenOptIn: boolean,
    setHasSeenOptIn: React.Dispatch<React.SetStateAction<boolean>>,
    isOptedIn: boolean
    setIsOptedIn: React.Dispatch<React.SetStateAction<boolean>>
    isPersonallyAddressed: boolean
    setIsPersonallyAddressed: React.Dispatch<React.SetStateAction<boolean>>
    hasConsentedToTracking: boolean
    setHasConsentedToTracking: React.Dispatch<React.SetStateAction<boolean>>
    feedback: string
    setFeedback: React.Dispatch<React.SetStateAction<string>>
}

export const SessionContext = React.createContext<SessionContextInterface>(
    {
        session: "",
        setSession: () => {},
        hasStartedSession: false,
        setHasStartedSession: () => { },
        hasSeenOptIn: false,
        setHasSeenOptIn: () => { },
        isOptedIn: false,
        setIsOptedIn: () => { },
        isPersonallyAddressed: false,
        setIsPersonallyAddressed: () => { },
        hasConsentedToTracking: false,
        setHasConsentedToTracking: () => { },
        feedback: "",
        setFeedback: () => { }
    }
)

export const useSession = () => React.useContext(SessionContext)

type Props = {
    children: React.ReactNode
}

export const SessionContextProvider: React.FC<Props> = (props: Props) => {
    const [session, setSession] = React.useState<string>("")
    const [hasStartedSession, setHasStartedSession] = React.useState<boolean>(false)
    const [hasSeenOptIn, setHasSeenOptIn] = React.useState<boolean>(false)
    const [isOptedIn, setIsOptedIn] = React.useState<boolean>(false)
    const [isPersonallyAddressed, setIsPersonallyAddressed] = React.useState<boolean>(false)
    const [hasConsentedToTracking, setHasConsentedToTracking] = React.useState<boolean>(false)
    const [feedback, setFeedback] = React.useState<string>("")
    
    return (
        <SessionContext.Provider
            value={{
                session,
                setSession,
                hasStartedSession,
                setHasStartedSession,
                hasSeenOptIn, 
                setHasSeenOptIn,
                isOptedIn,
                setIsOptedIn,
                isPersonallyAddressed,
                setIsPersonallyAddressed,
                hasConsentedToTracking,
                setHasConsentedToTracking,
                feedback,
                setFeedback
            }}
        >
            {props.children}
        </SessionContext.Provider>
    )
}


