

export type QuestionnaireType = {
    title: string
    nodes: QuestionnaireNode[]
}

export type QuestionnaireNode = {
    position: number
    content: QuestionnaireItem
}

export type QuestionnaireItem = {
    type: QuestionnaireItemType
    shortId: string
    question: string
    details: string | undefined
    comment: string | undefined
    options: QuestionnaireItemOption[]
}

export enum QuestionnaireItemType {
    BINARY = "binary",
    MULTIPLE_CHOICE = "multiple_choice",
    LIKERT = "likert"
}

export type QuestionnaireItemOption = {
    value: string
    label: string
    position: number | undefined
}

export const DEFAULT_BINARY_ITEM_OPTIONS: QuestionnaireItemOption[] = [
    {
        value: "0",
        label: "Nein",
        position: 0
    },
    {
        value: "1",
        label: "Ja",
        position: 1
    }
]

export type Response = {
    _id: string
    assignment: string
    items: ResponseItem[]
}

export type ResponseItem = {
    shortId: string
    value: string
}