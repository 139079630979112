import { createTheme } from "@mui/material";


export const appTheme = createTheme({
    palette: {
        primary: {
            main: "#EF865B",
            light: "#f49872", // 15% lighter
            dark: "#ae6345" // 15% darker
        },
        secondary: {
            main: "#0a3232",
            light: "#0f4b4b", // 15% lighter
            dark: "#0c2929" // 20% darker
        }
    },
    typography: {
        allVariants: {
            fontFamily: "Roboto Condensed",
        },
    },
})