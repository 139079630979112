import { Box, Button, ButtonGroup, Link, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as RouterLink } from "react-router-dom";
import { Check, CompanyInfo } from "../../types"


type IntroProps = {
    check: Check
    companyInfo: CompanyInfo
    onContinue: () => void
    onBack: () => void
}

export function Intro(props: IntroProps) {

    const { check, companyInfo, onContinue, onBack } = props

    return (
        <Box sx={{ height: "100dvh", overflow: "auto", display: "flex", flexDirection: "column", background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)", color: "white" }}>
            {/* Header */}
            <Box sx={{ background: "rgba(0, 0, 0, 0.25)", minHeight: { xs: 64, md: 96 }, display: "flex", alignItems: "center", px: { xs: 2, md: 5 }, py: 2, gap: { xs: 2, md: 5 } }}>
                <Link href={companyInfo.refLink}>
                    <Box component="img"
                        src={companyInfo.logo}
                        alt={"Logo von " + companyInfo.name}
                        sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: { xs: 48, md: 96 }, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                    />
                </Link>
                <Typography sx={{ typography: { xs: "h6", md: "h4" } }} color={"white"}>
                    {check.title}
                </Typography>
            </Box>
            {/* Intro Content */}
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                <Box sx={{ flexGrow: 1, maxWidth: 800, display: "flex", flexDirection: "column", justifyContent: "space-between", p: 2 }}>
                    <Typography component={"div"} variant="body1">
                        <p>
                            <Typography component={"span"} variant="h5">Herzlich willkommen zu unserem Nachhaltigkeits-Check.</Typography>
                        </p>
                        <p>
                            Es freut uns sehr, dass Sie sich die Zeit nehmen, um die derzeitigen Nachhaltigkeits-Aktivitäten Ihres Unternehmens zu bewerten und zu vergleichen.
                            Dieser Fragebogen ist ein wichtiger Schritt zur Verbesserung Ihres Engagements für Nachhaltigkeit, damit Ihr Unternehmen in einer immer bewussteren Wirtschaftslandschaft wettbewerbsfähig bleibt.
                        </p>
                        <p>
                            Der folgende Fragebogen enthält vierzehn Fragen mit je vier Antwortkategorien zu den Nachhaltigkeits-Bereichen Strategie und Führung, Stakeholder-Engagement, Maßnahmen und Transparenz.
                            Zur einfachen Handhabung ist jede Antwortoption mit einer Beschreibung und konkreten Beispielen versehen.
                        </p>
                        <p>
                            Nach Abschluss des Fragebogens erhalten Sie eine Auswertung Ihrer Nachhaltigkeits-Aktivitäten sowie eine Einschätzung Ihrer Anstrengungen im Vergleich zu anderen Unternehmen.
                            Darüber hinaus erhalten Sie konkrete Handlungsempfehlungen und weiterführende Informationen, um Ihre Nachhaltigkeits-Performance zu verbessern.
                        </p>
                        <p>
                            Bitte beantworten Sie die folgenden Fragen so genau wie möglich.
                            Ihre Antworten werden ausschließlich zur Erzeugung der Auswertung Ihrer Nachhaltigkeits-Aktivitäten verwendet und anschließend gelöscht.
                        </p>
                        <p>
                            Lassen Sie uns gemeinsam daran arbeiten, Ihr Unternehmen nachhaltiger und zukunftssicherer zu gestalten!
                        </p>
                    </Typography>
                    <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end", gap: 2 }}>
                        <Button onClick={onBack} variant="outlined" sx={{ width: 100, color: "white", borderColor: 'white', borderRadius: 50, ":hover": { borderColor: 'lightgrey' } }}>
                            Zurück
                        </Button>
                        <Button onClick={onContinue} variant="contained" color="primary" sx={{ width: 100, borderRadius: 50 }}>
                            Weiter
                        </Button>
                    </Box>
                </Box>

            </Box>
            {/* Footer */}
            <Box sx={{ height: "48px", background: "rgba(0, 0, 0, 0.25)", display: "flex", justifyContent: "space-between", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: 2 }}>
                <Link component={RouterLink} to="/privacy/impressum" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Impressum
                </Link>
                <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Datenschutz
                </Link>
                <Link component={RouterLink} to="/feedback" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Feedback
                </Link>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px", width: "100%" }}>
                <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                    <Button onClick={onBack} sx={{ width: "20%", borderRadius: 0 }}>
                        <ArrowBackIcon />
                    </Button>
                    <Button onClick={onContinue} sx={{ width: "80%", borderRadius: 0 }}>
                        Weiter
                    </Button>
                </ButtonGroup>
            </Box>
        </Box>
    )
}