import React from "react";
import { Link as RouterLink } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group";
import {
    Box,
    Button,
    ButtonGroup,
    Link,
    Typography
} from "@mui/material";
import ContactImage from "@/assets/tests/SustainabilityCheck/contact.jpg"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from "@/components/Slide";
import { Confirmation } from "./Confirmation";
import { Outro } from "./Outro";
import { Check, CompanyInfo } from "../../types";


type EndScreenProps = {
    check: Check
    companyInfo: CompanyInfo
    isOptedIn: boolean
    email: string
    page: number
    isNext: boolean
    onDownload: () => void
    onContact: () => void
    onAppointment: () => void
    onBack: () => void
    onContinue: () => void
    onFinish: () => void
}

export function EndScreen(props: EndScreenProps) {

    const { check, companyInfo, isOptedIn, email, page, isNext, onDownload, onContact, onAppointment, onBack, onContinue, onFinish } = props

    const refs = [React.createRef<HTMLDivElement>(), React.createRef<HTMLDivElement>()] // Refs for enter/exit animation
    let nodeRef = refs.at(page)

    return (
        <Box sx={{ height: "100dvh", overflow: "auto", display: "flex", flexDirection: "column", background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)", color: "white" }}>
            {/* Header */}
            <Box sx={{ background: "rgba(0, 0, 0, 0.25)", minHeight: { xs: 64, md: 96 }, display: "flex", alignItems: "center", px: { xs: 2, md: 5 }, py: 2, gap: { xs: 2, md: 5 } }}>
                <Link href={companyInfo.refLink}>
                    <Box component="img"
                        src={companyInfo.logo}
                        alt={"Logo von " + companyInfo.name}
                        sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: { xs: 48, md: 96 }, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                    />
                </Link>
                <Typography sx={{ typography: { xs: "h6", md: "h4" } }} color={"white"}>
                    {check.title}
                </Typography>
            </Box>
            {/* Content */}
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", alignItems: "center", py: 2, px: 2, overflowX: "hidden" }}>
                <Box sx={{ margin: "auto" }}>
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            classNames={isNext ? "left-to-right" : "right-to-left"}
                            timeout={500}
                            key={page}
                            nodeRef={nodeRef}
                        >
                            <Slide ref={nodeRef}>
                                {page === 0
                                    ? (
                                        <Confirmation
                                            email={email}
                                            isOptedIn={isOptedIn}
                                            onContinue={onContinue}
                                            onDownload={onDownload}
                                        />
                                    )
                                    : (
                                        <Outro
                                            contactImage={ContactImage}
                                            onContact={onContact}
                                            onAppointment={onAppointment}
                                            onBack={onBack}
                                            onFinish={onFinish}
                                        />
                                    )
                                }
                            </Slide>
                        </CSSTransition>
                    </SwitchTransition>
                </Box>
            </Box>
            {/* Footer */}
            <Box sx={{ height: "48px", background: "rgba(0, 0, 0, 0.25)", display: "flex", justifyContent: "space-between", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: 2 }}>
                <Link component={RouterLink} to="/privacy/impressum" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Impressum
                </Link>
                <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Datenschutz
                </Link>
                <Link component={RouterLink} to="/feedback" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Feedback
                </Link>
            </Box>
            <Box role="navigation" sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px", width: "100%" }}>
                {page === 0
                    ? (
                        <Button onClick={onContinue} fullWidth variant="contained" color="primary" sx={{ borderRadius: 0 }}>
                            Weiter
                        </Button>
                    )
                    : (
                        <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                            <Button onClick={onBack} sx={{ width: "20%", borderRadius: 0 }}>
                                <ArrowBackIcon />
                            </Button>
                            <Button onClick={onFinish} sx={{ width: "80%", borderRadius: 0, ":disabled": { bgcolor: "primary.dark" } }}>
                                Beenden
                            </Button>
                        </ButtonGroup>
                    )
                }
            </Box>
        </Box>
    )
}