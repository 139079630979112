import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
    AppBar,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Toolbar,
    Typography,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import ContactMailIcon from '@mui/icons-material/ContactMail';


export default function Privacy() {

    const navigate = useNavigate()

    const [mobileOpen, setMobileOpen] = React.useState(false)

    const xsHeaderHeight = "56px"
    const smHeaderHeight = "64px"
    const mdHeaderHeight = "0px"

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState)
    }

    const handleNavigateToHome = () => {
        navigate("")
    }

    const handleNavigateToPrivacyPolicy = () => {
        navigate("policy")
    }

    const handleNavigateToImpressum = () => {
        navigate("impressum")
    }

    React.useEffect(() => {
        document.title = "hival - Privacy Center"
    }, [])

    return (
        <React.Fragment>
            <Box
                sx={{
                    background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)"
                }}
            >
                <AppBar component="nav" position="static" color="transparent"
                    sx={{
                        display: { xs: "flex", md: "none" },
                        boxShadow: 0
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, }}
                        >
                            <MenuIcon sx={{ color: "white" }} />
                        </IconButton>
                        <Typography variant="h6" sx={{ flexGrow: 1, display: { xs: "None", sm: "block" }, color: "white" }}>
                            Privacy Center
                        </Typography>
                        <Box sx={{ display: { xs: "none", sm: "block" } }}>
                            <Button
                                onClick={handleNavigateToPrivacyPolicy}
                                sx={{ color: "#fff" }}
                            >
                                Datenschutzerklärung
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box component="nav">
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 350, background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)" },
                        }}
                    >
                        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', color: "white" }}>
                            <Typography variant="h6" sx={{ my: 2 }}>
                                Privacy Center
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemButton
                                        onClick={handleNavigateToHome}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <ListItemText primary="Startseite" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={handleNavigateToPrivacyPolicy}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <ListItemText primary="Datenschutzerklärung" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={handleNavigateToImpressum}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <ListItemText primary="Impressum" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Box>
                    </Drawer>
                </Box>
                {/* Main content */}
                <Box sx={{
                    maxHeight: {
                        xs: "calc(100vh - " + xsHeaderHeight + ")",
                        sm: "calc(100vh - " + smHeaderHeight + ")",
                        md: "calc(100vh - " + mdHeaderHeight + ")"
                    },
                    overflow: "auto",
                    display: "flex",
                    columnGap: { xs: 0, sm: 0, md: 0, lg: 0 },
                }}
                >
                    {/* Sidebar with navigation, shown only in desktop-mode */}
                    <Box sx={{
                        display: { xs: "None", md: "flex" },
                        flexDirection: "column",
                        gap: 0,
                        flexBasis: 350,
                        flexGrow: 1,
                        minWidth: 350,
                        maxWidth: 450,
                        height: "100vh",
                        maxHeight: "100vh",
                        overflow: "auto",
                        px: { xs: 1, sm: 2, md: 2, lg: 5 },
                        py: 5,
                        ml: { xs: 0, md: 0, lg: 5, xl: 10 },
                        borderRight: 1,
                        borderRightColor: "rgba(255, 255, 255, 0.2)"
                    }}>
                        <Box sx={{ color: "white", mb: 2 }}>
                            <Typography variant="caption">
                                hival
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                                <Typography variant="h5">
                                    Privacy Center
                                </Typography>
                            </Box>
                        </Box>

                        <Box onClick={handleNavigateToHome}
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                color: "white",
                                ":hover": { bgcolor: "rgba(0, 0, 0, 0.25)", cursor: "pointer" }
                            }}
                        >
                            <HomeIcon fontSize="medium" sx={{ mr: 2 }} />
                            <Typography variant="button">
                                Startseite
                            </Typography>
                        </Box>
                        <Box onClick={handleNavigateToPrivacyPolicy}
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                color: "white",
                                ":hover": { bgcolor: "rgba(0, 0, 0, 0.25)", cursor: "pointer" }
                            }}
                        >
                            <PrivacyTipIcon fontSize="medium" sx={{ mr: 2 }} />
                            <Typography variant="button">
                                Datenschutzerklärung
                            </Typography>
                        </Box>
                        <Box onClick={handleNavigateToImpressum}
                            sx={{
                                p: 2,
                                borderRadius: 1,
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                color: "white",
                                ":hover": { bgcolor: "rgba(0, 0, 0, 0.25)", cursor: "pointer" }
                            }}
                        >
                            <ContactMailIcon fontSize="medium" sx={{ mr: 2 }} />
                            <Typography variant="button">
                                Impressum
                            </Typography>
                        </Box>
                    </Box>
                    {/* Chapter content */}
                    <Box sx={{
                        px: { xs: 2, sm: 3, md: 5, lg: 10 },
                        display: "flex",
                        justifyContent: { xs: "center", md: "flex-start" },
                        flexBasis: 700, flexGrow: 1,
                        maxHeight: {
                            xs: "calc(100vh - " + xsHeaderHeight + ")",
                            sm: "calc(100vh - " + smHeaderHeight + ")",
                            md: "calc(100vh - " + mdHeaderHeight + ")"
                        },
                        overflow: "auto",
                    }}>
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}