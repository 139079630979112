import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link
} from "@mui/material"
import { Link as RouterLink } from "react-router-dom"


export type TrackingConsentProps = {
    open: boolean
    onAgree: () => void
    onDisagree: () => void
}

export default function TrackingConsent(props: TrackingConsentProps) {

    const { open, onAgree, onDisagree } = props

    return (
        <Dialog open={open} PaperProps={{ style: { backgroundColor: "#0A3131" } }}>
            <DialogTitle color={"white"} variant="h5">
                {"Tracking Ihres Verhaltens auf unserer Seite zulassen?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component={"div"} color="white">
                    <p>
                        Helfen Sie uns bei der Verbesserung Ihrer Nutzererfahrung.
                        Dies bedeutet, dass Sie Nutzungsdaten wie das Starten oder Beenden unserer Tests anonymisiert mit uns teilen, damit wir daraus
                        Ableitungen zur Weiterentwicklung der Seite treffen können.
                    </p>
                    <p>
                        Für mehr Informationen schauen Sie gerne in 
                        unserem <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer">{"Privacy Center"}</Link> vorbei.
                    </p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDisagree}>
                    Ablehnen
                </Button>
                <Button onClick={onAgree}>
                    Akzeptieren
                </Button>
            </DialogActions>
        </Dialog>
    )
}