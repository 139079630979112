import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Typography
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WebsiteTrackingDiagram from "../../../assets/images/privacy/website-tracking-dynamic-diagram.jpg"



export default function PrivacyPolicy() {

    const navigate = useNavigate()

    const { hash, key } = useLocation()
    React.useEffect(() => {
        if (hash) {
            const targetElement = document.getElementById(hash.substring(1))
            targetElement?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [key, hash])

    return (
        <Box
            sx={{
                minHeight: "100vh",
                maxWidth: 700,
                px: { xs: 2, md: 0 },
                py: 5,
                display: "flex", flexDirection: "column",
                gap: 2,
            }}
        >
            <Typography variant="h5" color={"white"}>
                Datenschutzerklärung
            </Typography>
            <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                <p>
                    Wir bei hival möchten, dass Sie verstehen, welche Daten wir erheben und wie wir sie verwenden.
                    Die folgende Datenschutzerklärung beantwortet daher folgende Fragen:
                </p>
            </Typography>
            <Box>
                <Box
                    onClick={() => { navigate("#data-collection") }}
                    sx={{
                        p: 2,
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                        borderBottom: 1,
                        borderColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "space-between",
                        ":hover": { cursor: "pointer" }
                    }}
                >
                    <Typography>
                        Welche Daten erheben wir?
                    </Typography>
                    <ChevronRightIcon />
                </Box>
                <Box
                    onClick={() => { navigate("#usage") }}
                    sx={{
                        p: 2,
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                        borderBottom: 1,
                        borderColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        justifyContent: "space-between",
                        ":hover": { cursor: "pointer" }
                    }}
                >
                    <Typography>
                        Wie verwenden wir Ihre Daten?
                    </Typography>
                    <ChevronRightIcon />
                </Box>
                <Box
                    onClick={() => { navigate("#security") }}
                    sx={{
                        p: 2,
                        bgcolor: "rgba(255, 255, 255, 0.9)",
                        display: "flex",
                        justifyContent: "space-between",
                        ":hover": { cursor: "pointer" }
                    }}
                >
                    <Typography>
                        Wie übermitteln und speichern wir Daten?
                    </Typography>
                    <ChevronRightIcon />
                </Box>
            </Box>
            <Box>
                <Typography id="data-collection" variant="h6" color={"white"}>
                    Welche Daten erheben wir?
                </Typography>
                <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                    <p><b>Ihre Antworten auf unsere Fragebögen</b></p>
                    <p>
                        Im Rahmen unseres Angebots füllen Sie einen Fragebogen aus und senden Ihre Antworten an uns zur Auswertung.
                        Dies geschieht automatisiert. Ihre Antworten werden <b>nicht</b> von uns gespeichert.
                        Das Ergebnis der Auswertung ist standardisiert und enthält keine personenbezogenen Daten.
                    </p>
                    <p><b>Name und E-Mail-Adresse</b></p>
                    <p>
                        Wir erheben Ihren Namen und Ihre E-Mail-Adresse, um mit Ihnen nach Abschluss unseres Fragebogens auf Wunsch einmalig in Kontakt zu treten. 
                        Die Erhebung geschieht freiwillig auf Basis Ihrer aktiven Zustimmung.
                        Wir nutzen lediglich die Angaben, die Sie mit uns teilen.
                    </p>
                    <p>
                        Diese Daten werden nur temporär erfasst und von unserem System genutzt, um Ihnen
                        automatisiert eine (ggf. personalisierte) E-Mail an die angegebene E-Mail-Adresse zukommen zu lassen.
                        Ihre persönlichen Angaben werden dabei nicht gespeichert.
                    </p>
                    <p><b>Feedback</b></p>
                    <p>
                        Vor Abschluss unserer Fragebögen haben Sie die Möglichkeit, Ihr Feedback mit uns zu teilen. 
                        Ihr Feedback wird von uns anonymisiert gespeichert und kann nicht von uns mit Ihren vorherigen Angaben in Verbindung gebracht werden.
                    </p>
                    <p><b>Nutzerinteraktionen</b></p>
                    <p>
                        Beim Aufruf unseres Angebots werden Sie darum gebeten, dem Tracking Ihres Verhaltens auf unserer Seite zuzustimmen.
                        Wir nutzen diese Daten, um unser Angebot weiterzuentwickeln und Ihre Nutzererfahrung zu verbessern.
                    </p>
                    <p>
                        Falls Sie Ihre Zustimmung geben, erfassen wir bestimmte Interaktionen mit unserer Seite.
                        Diese Daten werden innerhalb unseres eigenen Systems anonymisiert gespeichert.
                        Wir verwenden keine Services von Drittanbietern wie Google Analytics.
                        Außerdem verwenden wir keine Cookies, sodass Sie auch als wiederkehrende*r Nutzer*in nicht identifiziert werden können.
                    </p>
                    <p>
                        Die im Rahmen des Tracking erfassten Daten umfassen folgende Ereignisse:
                    </p>
                    <ul>
                        <li>Ereignis, wenn die <b>Session gestartet</b> wurde (nach Zustimmung zum Tracking)</li>
                        <li>Ereignis, wenn ein <b>Fragebogen gestartet</b> wurde</li>
                        <li>Ereignis, wenn nach Beantwortung aller Fragen ein <b>Fragebogen abgeschlossen</b> wurde</li>
                        <li>Ereignis, wenn die <b>Antworten gesendet</b> wurden (mit dem Zusatz, ob eine E-Mail versendet werden soll oder nicht)</li>
                        <li>Ereignis, wenn das Ergebnis der <b>Auswertung gedownloadet</b> wurde</li>
                        <li>Ereignis, wenn die <b>Session beendet</b> wurde</li>
                        <li>Ereignis, wenn die <b>Seite betreten oder verlassen</b> wurde</li>
                    </ul>
                </Typography>
            </Box>
            <Box>
                <Typography id="usage" variant="h6" color={"white"}>
                    Wie verwenden wir Ihre Daten?
                </Typography>
                <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                    <p>
                        Wir verwenden Ihre Daten nur für die beschriebenen Zwecke und nicht darüber hinaus.
                    </p>
                    <p><b>Automatisierte Auswertung Ihrer Antworten</b></p>
                    <p>
                        Ihre Antworten auf unsere Fragebögen werden verwendet, um daraus automatisiert eine Auswertung zu generieren.
                        Dabei werden die Daten nach einem vordefiniertem Schema verarbeitet und das Ergebnis entweder als Download oder
                        per E-Mail zur Verfügung gestellt.
                    </p>
                    <p>
                        Die Informationen, die Sie mit uns teilen, können explizit <b>nicht</b> dazu verwendet werden, ein Profil von Ihnen zu
                        erstellen, und werden auch nicht mit anderen Daten kombiniert.
                    </p>
                    <p><b>Einmalige Kontaktaufnahme per E-Mail</b></p>
                    <p>
                        Falls Sie sich dazu entschieden haben, Ihre Kontaktdaten (E-Mail-Adresse, ggf. persönliche Anrede) mit uns zu teilen, lassen
                        wir Ihnen eine E-Mail mit dem Ergebnis Ihrer Auswertung und weiteren Informationen zukommen.
                    </p>
                    <p>
                        Zum automatischen Versenden von E-Mails nutzen wir den cloudbasierten Dienst von Microsoft <b>Azure Communication Services</b> (ACS).
                        Hierfür senden wir eine Anfrage mit Ihrer E-Mail-Adresse und dem E-Mail-Inhalt an die Schnittstelle von ACS zur Verarbeitung des Auftrags.
                    </p>
                    <p><b>Weiterentwicklung unserer Angebote</b></p>
                    <p>
                        Ihre Daten zur Nutzerinteraktion werden zur Optimierung unserer Angebote eingesetzt, um ein besseres Verständnis für Ihre Nutzung
                        aufzubauen und die Nutzererfahrung zu verbessern.
                        Insbesondere versuchen wir folgende Fragen zu beantworten:
                    </p>
                    <ul>
                        <li>Wie häufig werden die Fragebögen abgebrochen? An welcher Stelle?</li>
                        <li>Wieviele Nutzer*innen schließen den Test vollständig ab?</li>
                        <li>Welche Fragen unserer Fragebögen müssen verbessert werden?</li>
                    </ul>
                    <p>Diese Daten werden gesammelt und analysiert, um daraus konkrete Verbesserungen abzuleiten und in unsere Entwicklung aufzunehmen.</p>
                </Typography>
            </Box>
            <Box>
                <Typography id="security" variant="h6" color={"white"}>
                    Wie übermitteln und speichern wir Daten?
                </Typography>
                <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                    <p>
                        Unsere Services sind innerhalb von Microsoft Azure (Region: Europa Westen) gehostet.
                        Die zugrundeliegende Infrastruktur unterliegt damit europäischen Datenschutzstandards.
                    </p>
                    <p>
                        Wir nutzen cloudbasierte Dienste von Azure wie <b>Static Web Apps</b>, <b>Azure Functions</b>, sowie <b>Azure Storage</b> zur Umsetzung unseres Angebots.
                        Daten, die im Betrieb der Web Applikation anfallen, werden in einem nicht öffentlich zugänglichen Storage Account gespeichert.
                    </p>
                    <p>
                        Die Daten zur Nutzerinteraktion und Feedback werden anonymisiert in einem Backend von hival erfasst und periodisch
                        in einem Data Warehouse zur Analyse gespeichert, zu dem nur hival Zugriff hat.
                        Unsere interne Analyse-Umgebung ist nicht öffentlich zugänglich und kann nur von freigeschalteten Personen eingesehen werden.
                    </p>
                    <Box component={"img"}
                        src={WebsiteTrackingDiagram}
                        sx={{
                            objectFit: "contain",
                            width: "100%"
                        }}
                    />
                    <p>
                        Die Sicherheit der Datenübertragung ist durch HTTPS-Verschlüsselung gewährleistet.
                        Innerhalb von Azure Services gespeicherte, ruhende Daten liegen verschlüsselt ab.
                    </p>
                </Typography>
            </Box>
        </Box>
    )
}