import React from "react";
import { Box } from "@mui/material";
import SustainabilityCheckJSON from "@/assets/tests/SustainabilityCheck/sustainability-check.json"
import SustainabilityCheckHeader from "@/assets/tests/SustainabilityCheck/test-header-image.jpg"
import BackgroundImage from "@/assets/images/entry_background.jpg"
import Logo from "@/assets/images/hival-logo.png"
import { Check } from "@/features/checks";
import { QuestionnaireNode, QuestionnaireType } from "@/features/questionnaires";


function MapSustainabilityCheckJsonToTest(json: typeof SustainabilityCheckJSON): QuestionnaireType {
    return {
        title: json.title,
        nodes: json.content.nodes.map(node => {
            return {
                position: node.position,
                content: {
                    type: node.content.type,
                    shortId: node.content.short_id,
                    question: node.content.question,
                    details: node.content.details,
                    comment: node.content.comment,
                    options: node.content.options.map(option => {
                        return {
                            value: option.value,
                            label: option.label,
                            position: option.position
                        }
                    })
                }
            } as QuestionnaireNode
        })
    }
}

export default function SustainabilityCheck() {

    const questionnaire: QuestionnaireType = MapSustainabilityCheckJsonToTest(SustainabilityCheckJSON)

    React.useEffect(() => {
        document.title = "hival - Nachhaltigkeitscheck"
    }, [])

    return (
        <Box sx={{ overflow: "hidden" }}>
            <Check
                questionnaire={questionnaire}
                check={{
                    title: questionnaire.title,
                    headerImage: SustainabilityCheckHeader,
                    headerImageAlt: "Spross in Erde, gehalten von einer Person",
                    backgroundImage: BackgroundImage
                }}
                companyInfo={{
                    name: "hival",
                    logo: Logo,
                    refLink: "https://hival.tech"
                }}
            />
        </Box>
    )
}