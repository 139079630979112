import { Box } from "@mui/material";
import Questionnaire from "@/features/questionnaires/routes/Questionnaire";
import { QuestionnaireType, useResponse } from "@/features/questionnaires";
import { useSession } from "@/contexts/SessionContext";
import { postEvent } from "../../api";
import { Check, CompanyInfo, Stage } from "../../types";


type QuestionnaireContainerProps = {
    check: Check
    companyInfo: CompanyInfo
    questionnaire: QuestionnaireType
    setTestStage: React.Dispatch<React.SetStateAction<Stage>>
}

export function QuestionnaireContainer(props: QuestionnaireContainerProps) {

    const { check, companyInfo, questionnaire, setTestStage } = props

    const { setResponse } = useResponse()
    const { session, hasConsentedToTracking } = useSession()

    const handleCancelTest = (itemId: string) => {
        // Reset response to no values selected
        setResponse(
            {
                _id: "",
                assignment: "",
                items: questionnaire.nodes.map(node => {
                    return {
                        shortId: node.content.shortId,
                        value: "NO_VALUE_SELECTED"
                    }
                })
            }
        )
        setTestStage("NotStarted")
        // Analytics
        if (hasConsentedToTracking) {
            postEvent(session, "test_canceled", { "item_id": itemId }, new Date())
        }
    }

    const handleFinishTest = () => {
        setTestStage("Finished")
        if (hasConsentedToTracking) {
            postEvent(session, "questionnaire_finished", undefined, new Date())
        }
    }

    return (
        <Box sx={{ height: "100dvh", maxHeight: "100vh", overflow: "auto" }}>
            <Questionnaire
                check={check}
                companyInfo={companyInfo}
                questionnaire={questionnaire}
                onCancel={handleCancelTest}
                onFinish={handleFinishTest}
            />
        </Box>
    )
}