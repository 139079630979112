import React from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { QuestionnaireType, ResponseProvider } from "@/features/questionnaires";

import { Check as CheckType, CompanyInfo, Stage } from "../types";

import Scale from "../components/Scale";
import WelcomeScreen from "../components/WelcomeScreen"
import Intro from "../components/Intro";
import QuestionnaireContainer from "../components/QuestionnaireContainer";
import MarketingOptIn from "../components/MarketingOptIn";
import SubmissionScreen from "../components/SubmissionScreen"
import EndScreen from "../components/EndScreen"


type Props = {
    questionnaire: QuestionnaireType
    check: CheckType
    companyInfo: CompanyInfo
}

export function Check(props: Props) {

    const { questionnaire, check, companyInfo } = props

    const nodeRef = React.useRef(null) // For stage enter/exit animation

    const [testStage, setTestStage] = React.useState<Stage>("NotStarted")

    return (
        <ResponseProvider questionnaire={questionnaire}>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    classNames={{
                        enter: 'fade-enter',
                        enterActive: 'fade-enter-active',
                        exit: 'fade-exit',
                        exitActive: 'fade-exit-active',
                    }}
                    timeout={300}
                    key={testStage}
                    nodeRef={nodeRef}
                >
                    {(testStage === "NotStarted" &&
                        <Scale ref={nodeRef}>
                            <WelcomeScreen
                                check={check}
                                companyInfo={companyInfo}
                                setTestStage={setTestStage}
                            />
                        </Scale>)
                        || (testStage === "IntroShown" &&
                            <Scale ref={nodeRef}>
                                <Intro
                                    check={check}
                                    companyInfo={companyInfo}
                                    setTestStage={setTestStage}
                                />
                            </Scale>)
                        || (testStage === "InProgress" &&
                            <Scale ref={nodeRef}>
                                <QuestionnaireContainer
                                    check={check}
                                    companyInfo={companyInfo}
                                    questionnaire={questionnaire}
                                    setTestStage={setTestStage}
                                />
                            </Scale>)
                        || (testStage === "Finished" &&
                            <Scale ref={nodeRef}>
                                <MarketingOptIn
                                    check={check}
                                    companyInfo={companyInfo}
                                    setTestStage={setTestStage}
                                />
                            </Scale>)
                        || (testStage === "OptInSeen" &&
                            <Scale ref={nodeRef}>
                                <SubmissionScreen
                                    check={check}
                                    companyInfo={companyInfo}
                                    setTestStage={setTestStage}
                                />
                            </Scale>)
                        || (testStage === "ResponseSent" &&
                            <Scale ref={nodeRef}>
                                <EndScreen 
                                    check={check}
                                    companyInfo={companyInfo}
                                />
                            </Scale>)
                        ||
                        null
                    }
                </CSSTransition>
            </SwitchTransition>
        </ResponseProvider>
    )
}