import React from 'react';
import { useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { CSSTransition, SwitchTransition } from "react-transition-group";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { useSession } from '@/contexts/SessionContext';

import { Fade } from '@/components/Fade';
import { appTheme } from '@/themes/theme';

import SustainabilityCheck from './tests/sustainability-check';
import Privacy from './privacy';
import PrivacyCenter from './privacy/center';
import PrivacyPolicy from './privacy/policy';
import Impressum from './privacy/impressum';
import FeedbackPortal from './feedback';
import FeedbackForm from './feedback/form';
import IssueForm from './feedback/report-an-issue';


const queryClient = new QueryClient()

export default function App() {

    const location = useLocation()
    const nodeRef = useRef(null) // For item enter/exit animation
    const { session, hasConsentedToTracking } = useSession()

    React.useEffect(() => {
        const handleVisibilityChange = async () => {
            if (document.visibilityState === 'hidden') {
                if (hasConsentedToTracking) {
                    // Perform your API call or analytics tracking here
                    try {
                        const data = {
                            session_id: session,
                            timestamp: (new Date()).toISOString(),
                            event_type: "page_left"
                        }
                        const jsonData = JSON.stringify(data);

                        const url = `/api/analytics?json=${encodeURIComponent(jsonData)}`;

                        navigator.sendBeacon(url)
                    } catch (error) {
                        console.error('API call error:', error)
                    }
                }
            } else {
                if (hasConsentedToTracking) {
                    try {
                        const data = {
                            session_id: session,
                            timestamp: (new Date()).toISOString(),
                            event_type: "page_entered"
                        }
                        const jsonData = JSON.stringify(data);

                        const url = `/api/analytics?json=${encodeURIComponent(jsonData)}`;

                        navigator.sendBeacon(url)
                    } catch (error) {
                        console.error('API call error:', error)
                    }
                }
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        }
    }, [hasConsentedToTracking, session])

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <SwitchTransition mode="out-in">
                    <CSSTransition
                        classNames={{
                            enter: 'fade-enter',
                            enterActive: 'fade-enter-active',
                            exit: 'fade-exit',
                            exitActive: 'fade-exit-active',
                        }}
                        timeout={300}
                        key={location.key}
                        nodeRef={nodeRef}
                    >
                        <Routes location={location}>
                            <Route index element={<Fade ref={nodeRef}><SustainabilityCheck /></Fade>} />
                            <Route path="privacy" element={<Privacy />}>
                                <Route index element={<PrivacyCenter />} />
                                <Route path="policy" element={<PrivacyPolicy />} />
                                <Route path="impressum" element={<Impressum />} />
                            </Route>
                            <Route path="feedback" element={<FeedbackPortal />} />
                            <Route path="feedback/form" element={<FeedbackForm />} />
                            <Route path="feedback/report-an-issue" element={<IssueForm />} />
                        </Routes>
                    </CSSTransition>
                </SwitchTransition>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
    )
}