import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    Link,
    TextField,
    Typography
} from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Check, CompanyInfo } from "../../types";


type User = {
    email: string
    formOfAddress: string
    title: string
    firstName: string
    lastName: string
}

type Session = {
    isOptedIn: boolean
    isPersonallyAddressed: boolean
    feedback: string
}

export type SubmissionScreenProps = {
    check: Check
    companyInfo: CompanyInfo
    session: Session
    user: User
    onBack: () => void
    onFeedbackChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onSubmit: () => void
}

export function SubmissionScreen(props: SubmissionScreenProps) {

    const { check, companyInfo, session, onBack, onFeedbackChange, onSubmit } = props

    return (
        <React.Fragment>
            <Box sx={{ height: "100dvh", overflow: "auto", display: "flex", flexDirection: "column", background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)", color: "white" }}>
                <Box sx={{ background: "rgba(0, 0, 0, 0.25)", minHeight: { xs: 64, md: 96 }, display: "flex", alignItems: "center", px: { xs: 2, md: 5 }, py: 2, gap: { xs: 2, md: 5 } }}>
                    <Link href={companyInfo.refLink}>
                        <Box component="img"
                            src={companyInfo.logo}
                            alt={"Logo von " + companyInfo.name}
                            sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: { xs: 48, md: 96 }, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                        />
                    </Link>
                    <Typography sx={{ typography: { xs: "h6", md: "h4" } }} color={"white"}>
                        {check.title}
                    </Typography>
                </Box>
                <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", py: 2 }}>
                    <Box sx={{ maxWidth: 700, display: "flex", flexDirection: "column", p: 2 }}>
                        <Typography variant="h5">
                            Abschluss
                        </Typography>
                        <Typography component={"div"}>
                            <p>
                                Bevor Sie Ihre Antworten zur Auswertung an uns senden, geben Sie uns gerne Feedback!
                                Ihr Feedback wird dabei anonymisiert gespeichert.
                            </p>
                            <p>
                                Schauen Sie auch gerne in unserem <Link component={RouterLink} to="/feedback" target="_blank" rel="noopener noreferrer">Feedback-Portal</Link> vorbei.
                            </p>
                        </Typography>
                        <TextField id="outlined-multiline-static"
                            variant="filled"
                            multiline
                            rows={8}
                            fullWidth
                            value={session.feedback}
                            label="Ihr Feedback"
                            placeholder="Meine Erfahrung mit diesem Fragebogen..."
                            onChange={onFeedbackChange}
                            sx={{
                                mt: 2,
                                "& .MuiFilledInput-root": {
                                    background: "white"
                                },
                                "& .MuiFilledInput-root:hover": {
                                    backgroundColor: "whitesmoke",
                                    // Reset on touch devices, it doesn't add specificity
                                    "@media (hover: none)": {
                                        backgroundColor: "white"
                                    }
                                },
                                "& .MuiFilledInput-root.Mui-focused": {
                                    backgroundColor: "whitesmoke",
                                }
                            }}
                        />
                        <Box sx={{ mt: 2, display: { xs: "none", md: "flex" }, justifyContent: "flex-end", gap: 2 }}>
                            <Button onClick={onBack} variant="outlined" sx={{ width: 100, color: "white", borderColor: 'white', borderRadius: 50, ":hover": { borderColor: 'lightgrey' } }}>
                                Zurück
                            </Button>
                            <Button onClick={onSubmit} variant="contained" color="primary" sx={{ width: 100, borderRadius: 50 }}>
                                Senden
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {/* Footer */}
                <Box sx={{ height: "48px", background: "rgba(0, 0, 0, 0.25)", display: "flex", justifyContent: "space-between", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: 2 }}>
                    <Link component={RouterLink} to="/privacy/impressum" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Impressum
                    </Link>
                    <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Datenschutz
                    </Link>
                    <Link component={RouterLink} to="/feedback" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                        Feedback
                    </Link>
                </Box>
                <Box sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px", width: "100%" }}>
                    <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                        <Button onClick={onBack} sx={{ width: "20%", borderRadius: 0 }}>
                            <ArrowBackIcon />
                        </Button>
                        <Button onClick={onSubmit} sx={{ width: "80%", borderRadius: 0 }}>
                            Senden
                        </Button>
                    </ButtonGroup>
                </Box>
            </Box>
        </React.Fragment>
    )
}