import React from "react"
import { Alert } from "@mui/material"
import BinaryItem from "./BinaryItem"
import MultipleChoiceItem from "./MultipleChoiceItem"
import { QuestionnaireItemOption, QuestionnaireItemType } from "../../types"


export type Action = | { type: "response_item_changed", payload: { shortId: string, value: string } }

type Props = {
    position: number
    shortId: string
    type: QuestionnaireItemType
    question: string
    details: string | undefined
    comment: string | undefined
    options: QuestionnaireItemOption[]
    value?: string
    update: (shortId: string, value: string) => void
}

export default function Item(props: Props) {

    const { position, shortId, type, question, details, comment, options, value, update } = props

    switch (type) {
        case QuestionnaireItemType.BINARY: {
            return (
                <React.Fragment>
                    <BinaryItem
                        position={position}
                        shortId={shortId}
                        question={question}
                        details={details}
                        comment={comment}
                        options={options}
                        value={value}
                        update={update}
                    />
                </React.Fragment>
            )
        }
        case QuestionnaireItemType.MULTIPLE_CHOICE: {
            return (
                <React.Fragment>
                    <MultipleChoiceItem
                        position={position}
                        shortId={shortId}
                        question={question}
                        details={details}
                        comment={comment}
                        options={options}
                        value={value}
                        update={update}
                    />
                </React.Fragment>
            )
        }
        default: {
            return (
                <Alert severity="error">
                    Darstellung des Itemtyps noch nicht implementiert!
                </Alert>
            )
        }
    }

}