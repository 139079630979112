import React from "react"
import { flushSync } from "react-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import {
    Box,
    Button,
    ButtonGroup,
    Link,
    Skeleton,
    Typography,
} from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { QuestionnaireType, QuestionnaireNode } from "../types";
import { useResponse } from "../providers/ResponseProvider";

import Item from "../components/Item/Item";
import Navigation from "../components/Navigation/Navigation";
import CancelQuestionnaire from "../components/CancelQuestionnaire";
import Slide from "../components/Slide";
import { Check, CompanyInfo } from "@/features/checks/types";


type NonEmptyArray<T> = [T, ...T[]];

type Node = {
    nodeRef: React.MutableRefObject<null>
} & QuestionnaireNode

type Props = {
    check: Check
    companyInfo: CompanyInfo
    questionnaire: QuestionnaireType
    onCancel: (itemId: string) => void
    onFinish?: () => void
}

export default function Questionnaire(props: Props) {

    const { check, companyInfo, questionnaire, onCancel, onFinish } = props

    const nodes = questionnaire.nodes.map(node => {
        return {
            position: node.position,
            content: node.content,
            nodeRef: React.createRef<HTMLDivElement>() // For item enter/exit animation
        }
    }) as NonEmptyArray<Node>

    const { response, setResponse } = useResponse()

    const [page, setPage] = React.useState<number>(1)
    const [currentNode, setCurrentNode] = React.useState<Node>(nodes[0])
    const [isNext, setIsNext] = React.useState<boolean>(true)

    const [cancelConfirmationDialogOpen, setCancelConfirmationDialogOpen] = React.useState<boolean>(false)

    const handleClickOnMenuItem = (nodePosition: number) => {
        moveToPage(nodePosition + 1)
    }

    const handleClickOnCancelTestButton = () => {
        setCancelConfirmationDialogOpen(true)
    }

    const handleCloseCancelConfirmationDialog = () => {
        setCancelConfirmationDialogOpen(false)
    }

    const forward = () => {
        if (page === nodes.length) {
            return
        }
        // Split rerender batch for animation so that css classes update before changing current node
        // See: https://github.com/reactwg/react-18/discussions/21 
        flushSync(() => {
            setIsNext(true)
        })
        flushSync(() => {
            const nextNode = nodes.find(node => node.position + 1 === (page + 1))
            if (nextNode !== undefined) {
                setPage(page + 1)
                setCurrentNode(nextNode)
            }
        })
    }

    const back = () => {
        if (page === 1) {
            return
        }
        // See comment above
        flushSync(() => {
            setIsNext(false)
        })
        flushSync(() => {
            const previousNode = nodes.find(node => node.position + 1 === (page - 1))
            if (previousNode !== undefined) {
                setPage(page - 1)
                setCurrentNode(previousNode)
            }
        })
    }

    const moveToPage = (newPage: number) => {
        // Change animation direction based on page
        flushSync(() => {
            if (newPage > page) {
                setIsNext(true)
            } else {
                setIsNext(false)
            }
        })
        // Move to page
        flushSync(() => {
            const newNode = nodes.find(node => node.position + 1 === newPage)
            if (newNode !== undefined) {
                setPage(newPage)
                setCurrentNode(newNode)
            }
        })
    }

    const getNumberOfCompletedItems = () => {
        let completed = 0
        response.items.forEach(item => {
            if (item.value !== "NO_VALUE_SELECTED") {
                completed += 1
            }
        })

        return completed
    }

    const isTestCompleted = () => {
        return getNumberOfCompletedItems() === questionnaire.nodes.length
    }

    const updateResponseItem = (shortId: string, value: string) => {

        let triggerForward = (response.items.find(item => item.shortId === shortId)?.value === "NO_VALUE_SELECTED");

        let updated = {
            ...response,
            items: response.items.map(item => {
                if (item.shortId === shortId) {
                    return {
                        shortId: shortId,
                        value: value
                    }
                } else {
                    return item
                }
            })
        }
        setResponse(updated)

        if (triggerForward) forward()
    }

    return (
        <React.Fragment>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)", overflowX: "hidden", overflowY: "auto" }}>
                <Box sx={{ background: "rgba(0, 0, 0, 0.25)", minHeight: { xs: 64, md: 96 }, display: "flex", alignItems: "center", px: { xs: 2, md: 5 }, py: 2, gap: { xs: 2, md: 5 } }}>
                    <Link href={companyInfo.refLink}>
                        <Box component="img"
                            src={companyInfo.logo}
                            alt={"Logo von " + companyInfo.name}
                            sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: { xs: 48, md: 96 }, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                        />
                    </Link>
                    <Typography sx={{ typography: { xs: "h6", md: "h4" } }} color={"white"}>
                        {check.title}
                    </Typography>
                </Box>
                {/* Questionnaire Content */}
                <Box role="main" aria-label="Questionnaire Content" aria-live="polite" sx={{ overflowX: "hidden", overflowY: { xs: "auto", md: "visible" }, flexGrow: 1, display: "flex", justifyContent: "center", p: { xs: 2, md: 2 }, mb: { xs: 1, md: 0 }, msOverflowStyle: "none", scrollbarWidth: "none", "::-webkit-scrollbar": { display: "none" } }}>
                    {/* Item with slide transition */}
                    <Box sx={{ margin: "auto" }}>
                        <SwitchTransition mode="out-in">
                            <CSSTransition
                                classNames={isNext ? "left-to-right" : "right-to-left"}
                                timeout={500}
                                key={page}
                                nodeRef={currentNode.nodeRef}
                            >
                                <Slide ref={currentNode.nodeRef}>
                                    <Box sx={{ maxWidth: 900, color: "white" }}>
                                        {currentNode !== undefined
                                            ? (
                                                <Item key={currentNode.position}
                                                    position={currentNode.position}
                                                    shortId={currentNode.content.shortId}
                                                    type={currentNode.content.type}
                                                    question={currentNode.content.question}
                                                    details={currentNode.content.details}
                                                    comment={currentNode.content.comment === "" ? undefined : currentNode.content.comment}
                                                    options={currentNode.content.options}
                                                    value={response.items.find(responseItem => responseItem.shortId === currentNode.content.shortId)?.value}
                                                    update={updateResponseItem}
                                                />
                                            ) : (
                                                <Box>
                                                    <Skeleton variant="text" width={500}></Skeleton>
                                                    <Skeleton variant="text" width={250}></Skeleton>
                                                    <Skeleton variant="text" width={250}></Skeleton>
                                                    <Skeleton variant="text" width={250}></Skeleton>
                                                    <Skeleton variant="text" width={250}></Skeleton>
                                                </Box>
                                            )
                                        }
                                    </Box>
                                </Slide>
                            </CSSTransition>
                        </SwitchTransition>
                    </Box>
                </Box>
                <Box role="navigation" sx={{ my: 1, display: { xs: "none", md: "flex" }, justifyContent: "center", gap: 5 }}>
                    {page !== 1
                        ? (
                            <Button aria-label="Back" onClick={back} sx={{ color: "white" }}>Vorherige Frage</Button>
                        )
                        : (
                            <Button aria-label="Cancel" onClick={handleClickOnCancelTestButton} sx={{ color: "white" }}>Abbrechen</Button>
                        )}

                    {page !== questionnaire.nodes.length
                        ? (
                            <Button aria-label="Forward" onClick={forward} sx={{ color: "white" }}>Nächste Frage</Button>
                        )
                        : (
                            <Button variant={isTestCompleted() ? "contained" : "text"} color="primary" aria-label="Finish" disabled={!isTestCompleted()} onClick={onFinish}>Abschließen</Button>
                        )}
                </Box>
                {/* Progressbar with Navigation Menu */}
                <Box sx={{ minHeight: "40px", width: '100%', backgroundColor: "rgba(0, 0, 0, 0.25)", position: "relative" }}>
                    <Box sx={{ height: '100%', width: `100%`, backgroundColor: "primary.main", borderRadius: 'inherit', borderBottom: 1, borderColor: "primary.dark", textAlign: 'right', transform: `translateX(${-100 + (getNumberOfCompletedItems() / (questionnaire.nodes.length) * 100)}%)`, transformOrigin: "left center", transition: "1.5s linear" }} />
                    <Box sx={{ position: "absolute", top: 0, right: 0, height: "100%" }}>
                        <Navigation
                            nodes={questionnaire.nodes}
                            response={response}
                            page={page}
                            onClickOnMenuItem={handleClickOnMenuItem}
                            isTestCompleted={isTestCompleted}
                            onCancelQuestionnaire={handleClickOnCancelTestButton}
                            onFinishQuestionnaire={onFinish}
                        />
                    </Box>
                </Box>
                {/* Navigation (mobile only) */}
                <Box role="navigation" aria-label="Mobile navigation" sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px" }}>
                    {page !== questionnaire.nodes.length
                        ? (
                            <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                                <Button aria-label="Back" onClick={back} color="primary" sx={{ width: "20%", borderRadius: 0 }}>
                                    <ArrowBackIcon />
                                </Button>
                                <Button aria-label="Forward" onClick={forward} color="primary" sx={{ width: "80%", borderRadius: 0 }}>
                                    Weiter
                                </Button>
                            </ButtonGroup>
                        )
                        : (
                            <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                                <Button aria-label="Back" onClick={back} sx={{ width: "20%", borderRadius: 0 }}>
                                    <ArrowBackIcon />
                                </Button>
                                <Button aria-label="Finish" disabled={!isTestCompleted()} onClick={onFinish} sx={{ width: "80%", borderRadius: 0, ":disabled": { bgcolor: "primary.dark" } }}>
                                    Abschließen
                                </Button>
                            </ButtonGroup>
                        )
                    }
                </Box>
            </Box>
            <CancelQuestionnaire
                open={cancelConfirmationDialogOpen}
                itemId={currentNode.content.shortId}
                onClose={handleCloseCancelConfirmationDialog}
                onSubmit={onCancel}
            />
        </React.Fragment>
    )
}