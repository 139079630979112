import { styled } from '@mui/material';


export const Fade = styled('div')(({ theme }) => ({
    '&.fade-enter': {
        opacity: 0.5,
        transform: "scale(1.01)"
    },
    '&.fade-enter-active': {
        opacity: 1,
        transform: "scale(1.0)",
        transition: "opacity 300ms, transform 300ms ease",

    },
    '&.fade-exit': {
        opacity: 1,
        transform: "scale(1.0)"
    },
    '&.fade-exit-active': {
        opacity: 0.5,
        transform: "scale(1.01)",
        transition: "opacity 300ms, transform 300ms ease",
    },
}))