import { Avatar, Box, Button, Link, Typography } from "@mui/material"


type OutroProps = {
    contactImage: string
    onContact: () => void
    onAppointment: () => void
    onBack: () => void
    onFinish: () => void
}

export function Outro(props: OutroProps) {

    const { contactImage, onContact, onAppointment, onBack, onFinish } = props

    return (
        <Box sx={{ maxWidth: 800, display: "flex", flexDirection: "column", gap: { xs: 2, sm: 2 } }}>
            <Box sx={{ px: 5, py: 3, bgcolor: "rgba(0, 0, 0, 0.25)", boxShadow: 1, borderRadius: 5, display: "flex", justifyContent: "center", alignItems: "center", gap: { xs: 1, sm: 5 }, flexWrap: { xs: "wrap-reverse", sm: "nowrap" } }}>
                <Box sx={{ textAlign: { xs: "center", sm: "left" } }}>
                    <Typography variant="h5" marginBottom={1}>Expertenanalyse anfordern</Typography>
                    <Typography>
                        Wir laden Sie herzlich ein, mit uns in Kontakt zu treten, um Ihre Ergebnisse zu besprechen und weitere Schritte auf Basis Ihrer Auswertung zu diskutieren.
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Avatar src={contactImage} sx={{ height: { xs: 96, sm: 64 }, width: { xs: 96, sm: 64 } }} />
                    <Typography marginTop={1} >Matti Glatte</Typography>
                    <Box sx={{ display: "flex" }}>
                        <Button variant="text" color="primary" onClick={onContact}>Kontakt</Button>
                        <Button variant="text" color="primary" onClick={onAppointment}>Termin</Button>
                    </Box>
                </Box>
            </Box>
            <Typography component={"div"}>
                <p>
                    Die Bewertung Ihrer Antworten ist ein erster wichtiger Schritt.
                    Um die Komplexität Ihrer Nachhaltigkeits-Performance vollständig zu erfassen und ein maßgeschneidertes Vorgehen zu entwickeln, empfehlen wir aber dringend eine detaillierte Analyse.
                </p>
                <p>
                    Falls Sie Fragen haben oder zusätzliche Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren. Wir freuen uns darauf, Sie auf Ihrer Nachhaltigkeitsreise zu begleiten.
                </p>
                <p>
                    Vielen Dank für Ihr Engagement für Nachhaltigkeit.
                    Gemeinsam machen wir den Unterschied.
                </p>
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end", gap: 2 }}>
                <Button onClick={onBack} variant="outlined" sx={{ width: 100, color: "white", borderColor: 'white', borderRadius: 50, ":hover": { borderColor: 'lightgrey' } }}>
                    Zurück
                </Button>
                <Button onClick={onFinish} variant="contained" color="primary" sx={{ width: 100, borderRadius: 50 }}>
                    Beenden
                </Button>
            </Box>
        </Box>
    )
}