import { v4 as uuidv4 } from "uuid"
import React from "react";
import { useSession } from "@/contexts/SessionContext";
import { createSession, postEvent } from "../../api";
import { Check, CompanyInfo, Stage } from "../../types";
import { WelcomeScreen } from "./WelcomeScreen";


type WelcomeScreenContainerProps = {
    check: Check
    companyInfo: CompanyInfo
    setTestStage: React.Dispatch<React.SetStateAction<Stage>>
}

export function WelcomeScreenContainer(props: WelcomeScreenContainerProps) {

    const { check, companyInfo, setTestStage } = props

    const [trackingConsentDialogOpen, setTrackingConsentDialogOpen] = React.useState<boolean>(false)
    const { hasStartedSession, setHasStartedSession, session, setSession, hasConsentedToTracking, setHasConsentedToTracking } = useSession()

    const handleStartCheck = () => {
        setTestStage("IntroShown")
        if (hasConsentedToTracking) {
            postEvent(session, "intro_shown", undefined, new Date())
        }
    }

    const handleTrackingConsentAgree = () => {
        setHasConsentedToTracking(true)
        postEvent(session, "session_started", undefined, new Date())
        setTrackingConsentDialogOpen(false)

    }

    const handleTrackingConsentDisagree = () => {
        setHasConsentedToTracking(false)
        setTrackingConsentDialogOpen(false)
    }

    React.useEffect(() => {
        if (!hasStartedSession) {
            const sessionId = uuidv4()
            setSession(sessionId)
            createSession(sessionId)
            setHasStartedSession(true)
            setTrackingConsentDialogOpen(true)
        }
    }, [hasStartedSession, setHasStartedSession, setSession])

    return (
        <WelcomeScreen
            check={check}
            companyInfo={companyInfo}
            onStartCheck={handleStartCheck}
            trackingConsentProps={{
                open: trackingConsentDialogOpen,
                onAgree: handleTrackingConsentAgree,
                onDisagree: handleTrackingConsentDisagree
            }}
        />
    )
}