import React from "react";
import {
    Box,
    Button,
    Grid,
    Link,
    Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function FeedbackPortal() {

    const navigate = useNavigate()

    const handleNavigateToFeedbackForm = () => {
        //navigate("form")
        window.open("https://forms.office.com/e/cGnkL9aQbB", '_blank');
    }

    const handleNavigateToIssueReporting = () => {
        //navigate("report-an-issue")
        window.open("https://forms.office.com/e/hwx33cKDmn", "_blank")
    }

    const handleClickOnSendMail = () => {
        const emailAddress = 'feedback@hival.tech'; // Replace with your desired email address
        const subject = 'Feedback zum hival Content Marketing Tool'; // Replace with your desired subject
        const body = 'Fügen Sie hier Ihr Feedback ein...'; // Replace with your desired email body

        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    height: "100vh",
                    background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    px: { xs: 2, sm: 3, md: 5, lg: 10 },
                    py: { xs: 5, sm: 5, md: 5, lg: 10 },
                    overflowY: "auto"
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <Box>
                        <Typography variant="overline" color={"white"}>
                            hival
                        </Typography>
                        <Typography variant="h5" color={"white"}>
                            Feedback Portal
                        </Typography>
                    </Box>
                    <Typography variant="body1" color={"white"}>
                        Willkommen in unserem Feedback-Portal. Wir freuen uns über Ihr Interesse, uns bei der Verbesserung unserer Angebote zu unterstützen!
                    </Typography>
                </Box>
                <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 5, xl: 10 }}>
                    <Grid item xs={12} md={12} lg={4} sx={{ display: "flex" }}>
                        <Box sx={{ bgcolor: "background.paper", boxShadow: 5, display: "flex", flexDirection: "column", p: { xs: 3, sm: 5, md: 5 }, gap: 2, justifyContent: "space-between" }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Feedback geben
                            </Typography>
                            <Typography component={"div"} variant="body2">
                                <p>
                                    Geben Sie uns Feedback zu Ihrer Nutzungserfahrung.
                                </p>
                                <p>
                                    Zur systematischen Erfassung Ihres Feedbacks haben wir ein Formular zu verschiedenen Aspekten unseres Angebots vorbereitet, das Sie ausfüllen können, um Ihr Feedback mit uns zu teilen.
                                    Sämtliche Felder in diesem Formular sind optional.
                                </p>
                            </Typography>
                            <Button variant="contained" onClick={handleNavigateToFeedbackForm}>Zum Feedback-Formular</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                        <Box sx={{ bgcolor: "background.paper", boxShadow: 5, display: "flex", flexDirection: "column", p: { xs: 3, sm: 5, md: 5 }, gap: 2, justifyContent: "space-between" }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Ein Problem melden
                            </Typography>
                            <Typography component={"div"} variant="body2">
                                <p>
                                    Melden Sie Fehler oder Probleme während der Nutzung.
                                </p>
                                <p>
                                    Um ein besseres Verständnis zu bekommen, wo genau der Fehler liegt und wie wir ihn beheben können, bitten wir Sie, das Problem möglichst genau zu beschreiben.
                                    Dies umfasst insbesondere das erwartete und tatsächliche Verhalten, sowie mögliche Schritte zur Reproduktion des Fehlers.
                                </p>
                            </Typography>
                            <Button variant="contained" onClick={handleNavigateToIssueReporting}>Zum Fehlerreport</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} sx={{ display: "flex" }}>
                        <Box sx={{ bgcolor: "background.paper", boxShadow: 5, display: "flex", flexDirection: "column", p: { xs: 3, sm: 5, md: 5 }, gap: 2, justifyContent: "space-between" }}>
                            <Typography gutterBottom variant="h5" component="div">
                                Sonstiges
                            </Typography>
                            <Typography component={"div"} variant="body2">
                                <p>
                                    Schreiben Sie uns eine E-Mail.
                                </p>
                                <p>
                                    Falls Sie keines unserer Formulare verwenden wollen oder uns anderweitig Feedback geben möchten, können Sie uns auch eine E-Mail an <Link color={"secondary"} href="mailto:feedback@hival.tech">feedback@hival.tech</Link> senden.
                                </p>
                                <p>
                                    Fügen Sie gerne Anhänge wie Screenshots oder Fehlermeldungen an diese E-Mail an.
                                </p>
                            </Typography>
                            <Button variant="contained" onClick={handleClickOnSendMail}>
                                Eine E-Mail senden
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}