import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImageDataCollection from "../../../assets/images/privacy/image-data-collection.jpg"
import ImageDataUsage from "../../../assets/images/privacy/image-data-usage.jpg"
import ImageDataSecurity from "../../../assets/images/privacy/image-data-security.jpg"


export default function PrivacyCenter() {

    const navigate = useNavigate()
    let location = useLocation()

    const handleNavigateToPrivacyPolicy = () => {
        navigate("policy")
    }

    const handleNavigateToCollectionSubPage = () => {
        navigate("policy#data-collection")
    }

    const handleNavigateToUsageSubPage = () => {
        navigate("policy#usage")
    }

    const handleNavigateToSecuritySubPage = () => {
        navigate("policy#security")
    }

    return (
        <Box
            sx={{
                maxWidth: 700,
                py: 5,
                display: "flex", flexDirection: "column",
                gap: 2
            }}
        >
            <Typography variant="h5" color={"white"}>
                Privacy Center
            </Typography>
            <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                <p>
                    Informieren Sie sich über unseren Umgang mit Ihren Daten und legen Sie passende
                    Datenschutzeinstellungen fest.
                </p>
            </Typography>
            <Box
                onClick={handleNavigateToPrivacyPolicy}
                sx={{
                    p: 2,
                    bgcolor: "rgba(255, 255, 255, 0.9)",
                    display: "flex",
                    justifyContent: "space-between",
                    ":hover": {cursor: "pointer"}
                }}
            >
                <Typography>
                    Zur Datenschutzerklärung
                </Typography>
                <ChevronRightIcon />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <Box
                        sx={{
                            display: "flex", flexDirection: "column",
                            boxShadow: 2,
                            bgcolor: "rgba(255, 255, 255, 0.9)",
                            ":hover": { boxShadow: 5 },
                        }}
                    >
                        <Box
                            component="img"
                            src={ImageDataCollection}
                            alt="Image showing connected cubes representing data"
                            sx={{
                                width: "100%",
                                height: 150,
                                objectFit: "cover"
                            }}
                        />
                        <Box sx={{ px: 2, py: 1 }}>
                            <Typography variant="caption">
                                Erfassung
                            </Typography>
                            <Typography variant="body1">
                                Welche Daten erfassen wir
                            </Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleNavigateToCollectionSubPage}
                                sx={{ my: 1 }}
                            >
                                Mehr erfahren
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box
                        sx={{
                            display: "flex", flexDirection: "column",
                            boxShadow: 2,
                            bgcolor: "rgba(255, 255, 255, 0.9)",
                            ":hover": { boxShadow: 5 },
                        }}
                    >
                        <Box
                            component="img"
                            src={ImageDataUsage}
                            alt="Image showing a person working on a laptop"
                            sx={{
                                width: "100%",
                                height: 150,
                                objectFit: "cover",
                            }}
                        />
                        <Box sx={{ px: 2, py: 1 }}>
                            <Typography variant="caption">
                                Verwendung
                            </Typography>
                            <Typography variant="body1">
                                Wie verwenden wir Ihre Daten
                            </Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleNavigateToUsageSubPage}
                                sx={{ my: 1 }}
                            >
                                Mehr erfahren
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <Box
                        sx={{
                            display: "flex", flexDirection: "column",
                            boxShadow: 2,
                            bgcolor: "rgba(255, 255, 255, 0.9)",
                            ":hover": { boxShadow: 5 },
                        }}
                    >
                        <Box
                            component="img"
                            src={ImageDataSecurity}
                            alt="Image showing a secured device"
                            sx={{
                                width: "100%",
                                height: 150,
                                objectFit: "cover"
                            }}
                        />
                        <Box sx={{ px: 2, py: 1 }}>
                            <Typography variant="caption">
                                Sicherheit
                            </Typography>
                            <Typography variant="body1">
                                Wie übermitteln wir Ihre Daten
                            </Typography>
                            <Button
                                variant="outlined"
                                fullWidth
                                onClick={handleNavigateToSecuritySubPage}
                                sx={{ my: 1 }}
                            >
                                Mehr erfahren
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Typography component="div" variant="body1" color={"white"} sx={{ maxWidth: 700 }}>
                <p>
                    Falls Sie weitere Fragen zum Datenschutz haben, nehmen Sie gerne Kontakt zu uns auf.
                </p>
            </Typography>
        </Box>
    )
}