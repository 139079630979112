import React from "react";
import { Link as RouterLink } from "react-router-dom"
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Link,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Check, CompanyInfo } from "../../types";


export type MarketingOptInProps = {
    check: Check
    companyInfo: CompanyInfo
    isOptedIn: boolean;
    isPersonallyAddressed: boolean;
    email: string;
    emailError: string;
    formOfAddress: string;
    title: string;
    firstName: string;
    lastName: string;
    onIsOptedInChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onIsPersonallyAddressedChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFormOfAddressChange: (event: SelectChangeEvent) => void;
    onTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFirstNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onLastNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onContinue: () => void;
    onBack: () => void;
}

export function MarketingOptIn(props: MarketingOptInProps) {

    const { check, companyInfo, isOptedIn, isPersonallyAddressed, email, emailError, formOfAddress, title, firstName, lastName, onIsOptedInChange, onIsPersonallyAddressedChange, onEmailChange, onFormOfAddressChange, onTitleChange, onFirstNameChange, onLastNameChange, onContinue, onBack } = props

    return (
        <Box sx={{ height: "100dvh", overflow: "auto", display: "flex", flexDirection: "column", background: "linear-gradient(135deg, rgba(10,50,50,1) 0%, rgba(15,75,75,1) 100%)", color: "white" }}>
            {/* Header */}
            <Box sx={{ background: "rgba(0, 0, 0, 0.25)", minHeight: { xs: 64, md: 96 }, display: "flex", alignItems: "center", px: { xs: 2, md: 5 }, py: 2, gap: { xs: 2, md: 5 } }}>
                <Link href={companyInfo.refLink}>
                    <Box component="img"
                        src={companyInfo.logo}
                        alt={"Logo von " + companyInfo.name}
                        sx={{ flexShrink: 0, flexGrow: 0, objectFit: "cover", width: { xs: 48, md: 96 }, maxHeight: "64px", transition: "transform 0.3s ease", ":hover": { transform: "scale(1.1)" } }}
                    />
                </Link>
                <Typography sx={{ typography: { xs: "h6", md: "h4" } }} color={"white"}>
                    {check.title}
                </Typography>
            </Box>
            {/* OptIn Content */}
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", py: 2, px: 2 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 4, maxWidth: 800 }}>
                    <Typography>
                        Im Anschluss an diesen Fragebogen würden wir Ihnen gerne einmalig eine E-Mail mit einer Auswertung senden und bei weiterem Interesse mit Ihnen in Kontakt treten. Dies ist freiwillig - und Ihre persönlichen Daten werden nicht von uns gespeichert.
                    </Typography>
                    <FormGroup sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <FormControlLabel
                            control={<Checkbox checked={isOptedIn} onChange={onIsOptedInChange} />}
                            label="Ich möchte, dass hival mir eine E-Mail zur Kontaktaufnahme sendet."
                        />
                        <FormControlLabel
                            disabled={!isOptedIn}
                            control={<Checkbox checked={isPersonallyAddressed} onChange={onIsPersonallyAddressedChange} />}
                            label="Ich möchte persönlich angesprochen werden."
                        />
                    </FormGroup>
                    {isOptedIn && (
                        <Box sx={{ bgcolor: "rgba(0, 0, 0, 0.25)", p: 3, borderRadius: 4, display: "flex", flexDirection: "column", gap: 2 }}>
                            <Typography variant="h5" sx={{ mb: 1 }}>Ihre Angaben</Typography>
                            <Grid container rowSpacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Typography>E-Mail-Adresse</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        error={emailError !== ""}
                                        helperText={emailError === "" ? undefined : emailError}
                                        placeholder="Eine gültige E-Mail-Adresse"
                                        value={email}
                                        onChange={onEmailChange}
                                        sx={{ "& .MuiOutlinedInput-root": { background: "white" } }}
                                    />
                                </Grid>
                            </Grid>
                            {isPersonallyAddressed && (
                                <Grid container rowSpacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography>Persönliche Ansprache</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                                            <FormControl variant="outlined" sx={{ flexGrow: 1, flexBasis: 200 }}>
                                                <Select
                                                    labelId="select-form-of-address-label"
                                                    id="select-form-of-address"
                                                    value={formOfAddress}
                                                    placeholder="Anrede"
                                                    onChange={onFormOfAddressChange}
                                                    sx={{ '& .MuiInputBase-input': { background: "white" } }}
                                                >
                                                    <MenuItem value={"Frau"}>Frau</MenuItem>
                                                    <MenuItem value={"Herr"}>Herr</MenuItem>
                                                    <MenuItem value={"Keine Angabe"}>Keine Angabe</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Titel (optional)"
                                                value={title}
                                                onChange={onTitleChange}
                                                sx={{ flexGrow: 1, flexBasis: 200, "& .MuiOutlinedInput-root": { background: "white" } }}
                                            />
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} sm={4} sx={{ display: { xs: "none", sm: "flex" } }} />

                                    <Grid item xs={12} sm={8}>
                                        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Vorname"
                                                value={firstName}
                                                onChange={onFirstNameChange}
                                                sx={{ flexGrow: 1, flexBasis: 200, "& .MuiOutlinedInput-root": { background: "white" } }}
                                            />
                                            <TextField
                                                variant="outlined"
                                                placeholder="Nachname"
                                                value={lastName}
                                                onChange={onLastNameChange}
                                                sx={{ flexGrow: 1, flexBasis: 200, "& .MuiOutlinedInput-root": { background: "white" } }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    )
                    }
                    <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end", gap: 2 }}>
                        <Button onClick={onBack} variant="outlined" sx={{ width: 100, color: "white", borderColor: 'white', borderRadius: 50, ":hover": { borderColor: 'lightgrey' } }}>
                            Zurück
                        </Button>
                        <Button onClick={onContinue} variant="contained" color="primary" sx={{ width: 100, borderRadius: 50 }}>
                            Weiter
                        </Button>
                    </Box>
                </Box>
            </Box>
            {/* Footer */}
            <Box sx={{ height: "48px", background: "rgba(0, 0, 0, 0.25)", display: "flex", justifyContent: "space-between", alignItems: "center", px: { xs: 2, md: 10, xl: 20 }, py: 2 }}>
                <Link component={RouterLink} to="/privacy/impressum" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Impressum
                </Link>
                <Link component={RouterLink} to="/privacy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Datenschutz
                </Link>
                <Link component={RouterLink} to="/feedback" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
                    Feedback
                </Link>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" }, minHeight: "64px", position: "sticky", bottom: "0px", width: "100%", zIndex: 1000 }}>
                <ButtonGroup variant="contained" fullWidth sx={{ boxShadow: 0 }}>
                    <Button onClick={onBack} sx={{ width: "20%", borderRadius: 0 }}>
                        <ArrowBackIcon />
                    </Button>
                    <Button onClick={onContinue} sx={{ width: "80%", borderRadius: 0 }}>
                        Weiter
                    </Button>
                </ButtonGroup>
            </Box>
        </Box>
    )
}