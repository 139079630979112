import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { useUser } from "@/contexts/UserContext";
import { useSession } from "../../../../contexts/SessionContext";
import { MarketingOptIn } from "./MarketingOptIn";
import { Check, CompanyInfo, Stage } from "../../types";
import { postEvent } from "../../api";


type MarketingOptInContainerProps = {
    check: Check
    companyInfo: CompanyInfo
    setTestStage: React.Dispatch<React.SetStateAction<Stage>>
}

export function MarketingOptInContainer(props: MarketingOptInContainerProps) {

    const { check, companyInfo, setTestStage } = props

    const {
        email,
        setEmail,
        formOfAddress,
        setFormOfAddress,
        title,
        setTitle,
        firstName,
        setFirstName,
        lastName,
        setLastName
    } = useUser()

    const {
        session,
        hasConsentedToTracking,
        isOptedIn,
        setIsOptedIn,
        isPersonallyAddressed,
        setIsPersonallyAddressed,
    } = useSession()

    const [emailError, setEmailError] = React.useState<string>("")

    const validateEmail = (email: string): string => {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,13})+$/

        if (!email) {
            return "E-Mail-Adresse wird benötigt"
        } else if (!pattern.test(email)) {
            return "Bitte fügen Sie eine gültige E-Mail-Adresse ein"
        } else {
            return ""
        }
    }

    const resetFormOfAdressFields = () => {
        setFormOfAddress("Keine Angabe")
        setTitle("")
        setFirstName("")
        setLastName("")
    }

    const handleIsOptedInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsOptedIn(event.target.checked)
        if (event.target.checked === false) {
            setIsPersonallyAddressed(false)
            resetFormOfAdressFields()
            setEmail("")
            setEmailError("")
        }
    }

    const handleIsPersonallyAddressedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPersonallyAddressed(event.target.checked)
        if (event.target.checked === false) {
            resetFormOfAdressFields()
        }
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const emailValue = event.target.value
        const error = validateEmail(emailValue)
        setEmail(emailValue)
        setEmailError(error)
    }

    const handleFormOfAddressChange = (event: SelectChangeEvent) => {
        setFormOfAddress(event.target.value)
    }

    const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value)
    }

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value)
    }

    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value)
    }

    const handleContinue = () => {
        setTestStage("OptInSeen");
        if (hasConsentedToTracking) {
            postEvent(session, "continue", { "target": "submission" }, new Date())
        }
    }

    const handleBack = () => {
        setTestStage("InProgress")
        if (hasConsentedToTracking) {
            postEvent(session, "back", { "target": "questionnaire" }, new Date())
        }
    }

    return (
        <MarketingOptIn 
            check={check}
            companyInfo={companyInfo}
            isOptedIn={isOptedIn}
            isPersonallyAddressed={isPersonallyAddressed}
            email={email}
            emailError={emailError}
            formOfAddress={formOfAddress}
            title={title}
            firstName={firstName}
            lastName={lastName}
            onIsOptedInChange={handleIsOptedInChange}
            onIsPersonallyAddressedChange={handleIsPersonallyAddressedChange}
            onEmailChange={handleEmailChange}
            onFormOfAddressChange={handleFormOfAddressChange}
            onTitleChange={handleTitleChange}
            onFirstNameChange={handleFirstNameChange}
            onLastNameChange={handleLastNameChange}
            onContinue={handleContinue}
            onBack={handleBack}
        />
    )
}