import React from "react";
import { Response, QuestionnaireType } from "../types";


interface ResponseContextInterface {
    response: Response
    setResponse: React.Dispatch<React.SetStateAction<Response>>
}

export const ResponseContext = React.createContext<ResponseContextInterface>(
    {
        response: {
            _id: "",
            assignment: "",
            items: []
        },
        setResponse: () => { }
    }
)

export const useResponse = () => React.useContext(ResponseContext)

type Props = {
    children: React.ReactNode
    questionnaire: QuestionnaireType // Use test as schema for response
}

export const ResponseProvider: React.FC<Props> = (props: Props) => {

    const { children, questionnaire } = props

    const [response, setResponse] = React.useState<Response>(
        {
            _id: "",
            assignment: "",
            items: questionnaire.nodes.map(node => {
                return {
                    shortId: node.content.shortId,
                    value: "NO_VALUE_SELECTED"
                }
            })
        }
    )

    return (
        <ResponseContext.Provider
            value={{
                response,
                setResponse
            }}
        >
            {children}
        </ResponseContext.Provider>
    )
}


