import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


type Props = {
    open: boolean
    itemId: string
    onClose: () => void
    onSubmit: (itemId: string) => void
}

export default function CancelQuestionnaire(props: Props) {

    const { open, itemId, onClose, onSubmit } = props

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} PaperProps={{ style: { backgroundColor: "#0A3131" } }}>
                <DialogTitle id="alert-dialog-title" color="white">
                    {"Möchten Sie den Test wirklich abbrechen?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="white">
                        Der Test wird abgebrochen und Sie kehren zum Start zurück.
                        Ihre bereits gegebenen Antworten werden dabei zurückgesetzt!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Zurück</Button>
                    <Button onClick={() => onSubmit(itemId)} autoFocus>
                        Bestätigen
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}