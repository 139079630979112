import { ClickAwayListener, IconButton, Tooltip } from "@mui/material"
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import { teal } from "@mui/material/colors";


type Props = {
    comment: string
    open: boolean
    handleOpen: () => void
    handleClose: () => void
}

export default function InfoButton(props: Props) {

    const { comment, open, handleOpen, handleClose } = props

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                onClose={handleClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={comment}
            >
                <IconButton
                    onClick={handleOpen}
                >
                    <PublicTwoToneIcon
                        fontSize="large"
                        sx={{
                            color: teal[800]
                        }}
                    />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    )
}