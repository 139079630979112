import { Box, Button, Typography } from "@mui/material"
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DownloadIcon from '@mui/icons-material/Download';

type ConfirmationProps = {
    isOptedIn: boolean
    email: string
    onDownload: () => void
    onContinue: () => void
}

export function Confirmation(props: ConfirmationProps) {

    const { isOptedIn, email, onDownload, onContinue } = props

    return (
        <Box sx={{ maxWidth: 800, display: "flex", flexDirection: "column", gap: { xs: 2, sm: 2 } }}>
            <Box marginBottom={1}>
                <Typography variant="h5" marginBottom={1}>Ihre Auswertung</Typography>
                <Typography>Vielen Dank für Ihre Teilnahme an unserem Nachhaltigkeits-Check.</Typography>
            </Box>
            <Box sx={{ p: {xs: 3, sm: 5}, bgcolor: "rgba(0, 0, 0, 0.25)", boxShadow: 1, borderRadius: 5, display: "flex", gap: 5 }}>
                {/* Confirmation for email contact or direct download */}
                {isOptedIn ? (
                    <>
                        <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
                            <AttachEmailIcon fontSize="large" />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            <Typography variant="body1">
                                Ihr Ergebnis wurde per E-Mail an Sie gesendet.
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="caption">
                                    Ihre E-Mail-Adresse:
                                </Typography>
                                <Box sx={{ p: 2, border: 1, borderColor: "lightgrey" }}>
                                    <Typography>
                                        {email}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}>
                            <DownloadIcon fontSize="large" />
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: { xs: "center", sm: "flex-start" }, flexGrow: 1 }}>
                            <Typography variant="subtitle1">
                                Ihr Ergebnis steht zum Download bereit
                            </Typography>
                            <Button variant="contained" onClick={onDownload} sx={{ mt: 2, maxWidth: 200, borderRadius: 50 }}>
                                Download
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
            <Typography component={"div"}>
                <p>
                    Sie haben einen wichtigen Schritt in Richtung einer nachhaltigeren und wettbewerbsfähigen Zukunft für Ihr Unternehmen gemacht.
                    Ihre Auswertung steht Ihnen nun direkt als PDF zur Verfügung.
                </p>
                <p>
                    Dieses Dokument enthält eine Bewertung Ihrer Nachhaltigkeits-Aktivitäten im Vergleich zu anderen Unternehmen sowie Ihre Zuordnung zu einer unserer drei Kategorien: Newcomer, Advanced und Pioneer.
                    Zusätzlich finden Sie in der Auswertung spezifische Handlungsempfehlungen.
                </p>
            </Typography>
            <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: "flex-end", gap: 2 }}>
                <Button onClick={onContinue} variant="contained" color="primary" sx={{ width: 100, borderRadius: 50 }}>
                    Weiter
                </Button>
            </Box>
        </Box>
    )
}