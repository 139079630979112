import { Intro } from "./Intro"
import { Check, CompanyInfo, Stage } from "../../types";
import { useSession } from "@/contexts/SessionContext";
import { postEvent } from "../../api";


type IntroContainerProps = {
    check: Check
    companyInfo: CompanyInfo
    setTestStage: React.Dispatch<React.SetStateAction<Stage>>
}

export function IntroContainer(props: IntroContainerProps) {

    const { check, companyInfo, setTestStage } = props

    const { session, hasConsentedToTracking } = useSession()

    const handleContinue = () => {
        setTestStage("InProgress")
        if (hasConsentedToTracking) {
            postEvent(session, "questionnaire_started", undefined, new Date())
        }
    }

    const handleBack = () => {
        setTestStage("NotStarted")
        if (hasConsentedToTracking) {
            postEvent(session, "back", { target: "welcome" }, new Date())
        }
    }

    return (
        <Intro
            check={check}
            companyInfo={companyInfo}
            onContinue={handleContinue}
            onBack={handleBack}
        />
    )
}