import React from "react"
import {
    Box,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material"
import { QuestionnaireItemOption } from "../../types";
import InfoButton from "./InfoButton";


type Props = {
    position: number
    shortId: string
    question: string
    details?: string
    comment?: string
    options: QuestionnaireItemOption[]
    value?: string
    update: (shortId: string, value: string) => void
}

export default function BinaryItem(props: Props) {

    const { position, shortId, question, details, comment, options, value, update } = props

    const [commentOpen, setCommentOpen] = React.useState<boolean>(false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        update(shortId, event.target.value)
    }

    const handleCommentClose = () => {
        setCommentOpen(false)
    }

    const handleCommentOpen = () => {
        setCommentOpen(true)
    }

    return (
        <Box>
            <Stack spacing={1}>
                <Typography sx={{ typography: { xs: "subtitle2", lg: "h6" } }}>
                    {(position + 1).toString() + ". " + question}
                </Typography>
                <FormControl>
                    <RadioGroup
                        name="radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                    >
                        {options.map((option, index) => {
                            return (
                                <Box key={index} sx={{ background: "rgba(0, 0, 0, 0.25)", p: 3, my: 1, borderRadius: 2, ":hover": { background: "rgba(0, 0, 0, 0.15)" } }}>
                                    <FormControlLabel
                                        value={option.value}
                                        control={<Radio />}
                                        label={<Typography sx={{ typography: { xs: "body2", lg: "body1" } }}>{option.label}</Typography>}
                                    />
                                </Box>
                            )
                        })}
                    </RadioGroup>
                </FormControl>
                {comment !== undefined && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end"
                        }}
                    >
                        <InfoButton
                            comment={comment}
                            open={commentOpen}
                            handleOpen={handleCommentOpen}
                            handleClose={handleCommentClose}
                        />
                    </Box>
                )}
            </Stack>
        </Box>
    )
}