import React from "react";
import { useSession } from "@/contexts/SessionContext";
import { useUser } from "@/contexts/UserContext";
import { createContact, postEvent } from "../../api";
import { useResponse } from "@/features/questionnaires";
import { Check, CompanyInfo, Stage } from "../../types";
import { SubmissionScreen } from "./SubmissionScreen";


type Props = {
    check: Check
    companyInfo: CompanyInfo
    setTestStage: React.Dispatch<React.SetStateAction<Stage>>
}

export function SubmissionScreenContainer(props: Props) {

    const { check, companyInfo, setTestStage } = props
    const { session, isOptedIn, isPersonallyAddressed, hasConsentedToTracking, feedback, setFeedback } = useSession()
    const { email, formOfAddress, title, firstName, lastName } = useUser()
    const { response } = useResponse()

    const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFeedback(event.target.value)
    }

    const handleBack = () => {
        setTestStage("Finished")
        if (hasConsentedToTracking) {
            postEvent(session, "back", { "target": "questionnaire" }, new Date())
        }
    }

    const handleSubmit = async () => {
        await createContact(
            session,
            {
                isOptedIn: isOptedIn,
                isPersonallyAddressed: isPersonallyAddressed,
                email: email === "" ? undefined : email,
                firstName: firstName === "" ? undefined : firstName,
                lastName: lastName === "" ? undefined : lastName,
                formOfAddress: formOfAddress === "" ? undefined : formOfAddress,
                title: title === "" ? undefined : title
            },
            response,
            feedback === "" ? undefined : feedback
        )
        // TODO: Exception handling
        setTestStage("ResponseSent")
        if (hasConsentedToTracking) {
            postEvent(session, "response_sent", { mail_requested: isOptedIn }, new Date())
        }
    }

    return (
        <SubmissionScreen
            check={check}
            companyInfo={companyInfo}
            session={{
                isOptedIn: isOptedIn,
                isPersonallyAddressed: isPersonallyAddressed,
                feedback: feedback
            }} 
            user={{
                email: email,
                formOfAddress: formOfAddress,
                title: title,
                firstName: firstName,
                lastName: lastName
            }} 
            onBack={handleBack} 
            onFeedbackChange={handleFeedbackChange} 
            onSubmit={handleSubmit}
        />
    )
}