import { styled } from "@mui/material"


const Slide = styled('div')({
    '&.left-to-right-enter': {
        opacity: 0,
        transform: "translateX(-10%)"
    },
    '&.left-to-right-enter-active': {
        opacity: 1,
        transform: "translateX(0%)",
        transition: "opacity 500ms, transform 500ms ease-in-out",
    },
    '&.left-to-right-exit': {
        opacity: 1,
        transform: "translateX(0%)",
    },
    '&.left-to-right-exit-active': {
        opacity: 0,
        transform: "translateX(10%)",
        transition: "opacity 500ms, transform 500ms ease-in-out",
    },
    '&.right-to-left-enter': {
        opacity: 0,
        transform: "translateX(10%)"
    },
    '&.right-to-left-enter-active': {
        opacity: 1,
        transform: "translateX(0%)",
        transition: "opacity 500ms, transform 500ms ease-in-out",
    },
    '&.right-to-left-exit': {
        opacity: 1,
        transform: "translateX(0%)",
    },
    '&.right-to-left-exit-active': {
        opacity: 0,
        transform: "translateX(-10%)",
        transition: "opacity 500ms, transform 500ms ease-in-out",
    },
})

export default Slide;