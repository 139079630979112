import { createAPIRequest } from '@/api';
import { User } from '@/types/User';
import { Response } from '@/features/questionnaires/types';

import { endpoints } from './endpoints';


/**
  * Creates a contact request under the specified session.
  * 
  * @param sessionId - Current user's session ID
  * @param user - User related information for getting into contact like e-mail address or name
  * @param response - The user's response to the test described by an item specific ID and a value
  * @param feedback - Feedback from the user regarding the test
  * @returns None
  */
export async function createContact(sessionId: string, user: User, response: Response, feedback: string | undefined) {
    return await createAPIRequest(endpoints.createContact, {
        data: {
            session_id: sessionId,
            is_opted_in: user.isOptedIn,
            is_personally_addressed: user.isPersonallyAddressed,
            email: user.email,
            form_of_address: user.formOfAddress,
            title: user.title,
            first_name: user.firstName,
            last_name: user.lastName,
            response: response.items.map(item => {
                return {
                    short_id: item.shortId,
                    value: item.value
                }
            }),
            feedback: feedback
        }
    });
}


/**
 * Create a session with the specified ID.
 * @param sessionId - ID of the session which will be created
 * @returns None
 */
export async function createSession(sessionId: string) {
    return await createAPIRequest(endpoints.createSession, {
        data: {
            session_id: sessionId
        }
    })
}


/**
 * Fetches the report assigned for the specified session.
 * @param sessionId - Current user's session ID
 * @returns Report PDF in binary format (arraybuffer)
 */
export async function getReport(sessionId: string): Promise<ArrayBuffer> {
    return await createAPIRequest(endpoints.getReport, {
        params: {
            session_id: sessionId
        },
        responseType: "arraybuffer"
    })
}


/**
 * Sends an event for analytics.
 * @param sessionId 
 * @param eventType 
 * @param eventData 
 * @param timestamp 
 * @returns 
 */
export async function postEvent(sessionId: string, eventType: string, eventData: any, timestamp: Date) {
    return await createAPIRequest(endpoints.postEvent, {
        data: {
            session_id: sessionId,
            timestamp: timestamp.toISOString(),
            event_type: eventType,
            event_data: eventData
        }
    })
}
