import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './pages';

import reportWebVitals from './reportWebVitals';
import { SessionContextProvider } from './contexts/SessionContext';
import { UserContextProvider } from './contexts/UserContext';

if (process.env.REACT_APP_API_MOCKING === 'true' && process.env.NODE_ENV === "development") {
  const { worker } = require("./test/mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SessionContextProvider>
        <UserContextProvider>
          <App />
        </UserContextProvider>
      </SessionContextProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
