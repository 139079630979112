import React from "react"
import { flushSync } from "react-dom";
import { useUser } from "@/contexts/UserContext";
import { getReport, postEvent } from "../../api";
import { useSession } from "@/contexts/SessionContext";
import { EndScreen } from "./EndScreen";
import { Check, CompanyInfo } from "../../types";

type EndScreenContainerProps = {
    check: Check
    companyInfo: CompanyInfo
}

export function EndScreenContainer(props: EndScreenContainerProps) {

    const { check, companyInfo } = props

    const { session, isOptedIn, hasConsentedToTracking } = useSession()
    const { email } = useUser()

    const [page, setPage] = React.useState<number>(0)
    const [isNext, setIsNext] = React.useState<boolean>(true)

    const handleDownload = async () => {
        const reportPdfData = await getReport(session)
        const blob = new Blob([reportPdfData], { type: "application/pdf" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = "hival - Nachhaltigkeits-Check.pdf"
        link.click()
        if (hasConsentedToTracking) {
            postEvent(session, "report_downloaded", undefined, new Date())
        }
    }

    const handleContact = async () => {
        const emailAddress = "matti.glatte@hival.tech"
        const mailtoLink = `mailto:${emailAddress}`;

        if (hasConsentedToTracking) {
            await postEvent(session, "click", { target: "contact"}, new Date())
        }

        window.location.href = mailtoLink;
    }

    const handleAppointment = async () => {
        Object.assign(document.createElement('a'), {
            target: '_blank',
            rel: 'noopener noreferrer',
            href: "https://outlook.office365.com/owa/calendar/hival1@hival.tech/bookings/",
        }).click()

        if (hasConsentedToTracking) {
            await postEvent(session, "click", { target: "appointment"}, new Date())
        }
    }

    const handleBack = () => {
        flushSync(() => {
            setIsNext(false)
        })
        flushSync(() => {
            setPage(0);
        })
    }

    const handleContinue = () => {
        flushSync(() => {
            setIsNext(true)
        })
        flushSync(() => {
            setPage(1);
        })
    }

    const handleFinish = async () => {
        if (hasConsentedToTracking) {
            await postEvent(session, "session_ended", undefined, new Date())
        }
        window.location.replace('https://hival.tech')
    }

    return (
        <EndScreen
            check={check}
            companyInfo={companyInfo}
            isOptedIn={isOptedIn}
            email={email}
            page={page}
            isNext={isNext}
            onDownload={handleDownload}
            onContact={handleContact}
            onAppointment={handleAppointment}
            onBack={handleBack}
            onContinue={handleContinue}
            onFinish={handleFinish}
        />
    )
}